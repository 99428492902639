import { baseAxiosKaltimtara } from "../../index";

export async function getAllPengajuanKaltimtara(dispatch, data, history) {
  try {
    const response = await baseAxiosKaltimtara.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPencairanKaltimtara(dispatch, data, history) {
  try {
    const response = await baseAxiosKaltimtara.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPencairanKaltimtara(dispatch, data, history) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webDashboard/getTotalPencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTargetUserKaltimtara(dispatch, data, history) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webDashboard/getTargetUserLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function revisiPengajuanKaltimtara(data) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webPengajuan/revisiPengajuanDariAtasan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanBPDKaltimtara(data) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webApiBpd/pengajuan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    // console.log(response, "DARI AXIOSSSSSS");
    return response;
  } catch (err) {
    return err.response;
  }
}
