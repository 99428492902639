const initialState = {
  listKorwil: [],
  statusInputTarget: true,
  listTargetKorwil: [],
};

const korwilReducerKalteng = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLKORWIL":
      return {
        ...state,
        listKorwil: data,
      };
    case "SET_STATUS_INPUT_TARGET_KORWIL":
      return {
        ...state,
        statusInputTarget: data,
      };
    case "SET_ALL_TARGET_KORWIL":
      return {
        ...state,
        listTargetKorwil: data,
      };
    default:
      return state;
  }
};
export default korwilReducerKalteng;
