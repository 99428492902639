import { baseAxioKalsel } from "../../index";

export async function getAllTotalSupervisorKalsel(dispatch) {
  try {
    const response = await baseAxioKalsel.get(
      "webDashboard/getTotalSupervisor",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_SUPERVISOR", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalAreaManagerKalsel(dispatch) {
  try {
    const response = await baseAxioKalsel.get(
      "webDashboard/getTotalAreaManager",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_AREAMANAGER", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalMarketingKalsel(dispatch) {
  try {
    const response = await baseAxioKalsel.get(
      "webDashboard/getTotalMarketing",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_MARKETING", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalCabangKalsel(dispatch) {
  try {
    const response = await baseAxioKalsel.get("webDashboard/getTotalCabang", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_CABANG", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalPengajuanKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.post(
      "webPengajuan/getAllTotalPengajuan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_PENGAJUAN", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanCabangKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.post(
      "webDashboard/getGrafikPencairanCabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanUserKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.post(
      "webDashboard/getGrafikPencairanPerBulanByLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    // console.log(response, "PPPPPPPP");
    return response;
  } catch (err) {
    return err.response;
  }
}
