import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import ReactDatetime from "react-datetime";

import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import { getAllTotalAreaManagerKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";
import { getAllTotalPengajuanKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";
import { getGrafikPencairanCabangKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";
import { getGrafikPencairanUserKaltimtara } from "stores";
import { getAllTotalSupervisorKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";
import { getAllTotalMarketingKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";
import { getAllTotalCabangKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";

function DetailKaltimtara() {
  const dispatch = useDispatch();
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  const auth = useSelector((state) => state.authReducer);
  const cardTotalPengajuanKaltimtara = useSelector(
    (state) => state.dashboardUtamaReducerKaltimtara
  );
  const [listYear, setListYear] = React.useState([]);
  let yearNow = new Date().getFullYear();
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [currentPageKaltimtara, setCurrentPageKaltimtara] = React.useState(1);

  const [dataGrafikCabangKaltimtara, setDataGrafikCabangKaltimtara] =
    React.useState([]);
  const [dataGrafikUserKaltimtara, setDataGrafikUserKaltimtara] =
    React.useState([]);
  const [totalSpvKaltimtara, setTotalSpvKaltimtara] = React.useState([]);
  const [totalCbgKaltimtara, setTotalCbgKaltimtara] = React.useState([]);
  const [totalAMKaltimtara, setTotalAMKaltimtara] = React.useState([]);
  const [totalMarketingKaltimtara, setTotalMarketingKaltimtara] =
    React.useState([]);
  const [dataAllGrafikCabangKaltimtara, setDataAllGrafikCabangKaltimtara] =
    React.useState([]);
  const [modalDetailKaltimtara, setModalDetailKaltimtara] =
    React.useState(false);
  const itemsPerPageKaltimtara = 10;

  const bulanDataKaltimtara = dataGrafikUserKaltimtara.map(
    (item) => item.bulan
  );

  const pencairanDataKaltimtara = dataGrafikCabangKaltimtara.map(
    (item) => item.totalPencairan
  );
  const targetDataKaltimtara = dataGrafikCabangKaltimtara.map(
    (item) => item.totalTarget
  );

  const pencairanDataUserKaltimtara = dataGrafikUserKaltimtara.map(
    (item) => item.totalNominalPencairan
  );
  const targetDataUserKaltimtara = dataGrafikUserKaltimtara.map(
    (item) => item.totalNominalTarget
  );
  const abjadCabangKaltimtara = dataGrafikCabangKaltimtara.map((_, index) =>
    String.fromCharCode(65 + index)
  );

  const chartDataKaltimtara = {
    labels: abjadCabangKaltimtara,
    series: [pencairanDataKaltimtara, targetDataKaltimtara],
  };

  const chartDataUserKaltimtara = {
    labels: bulanDataKaltimtara,
    series: [pencairanDataUserKaltimtara, targetDataUserKaltimtara],
  };

  const handlePageChangeKaltimtara = (page) => {
    setCurrentPageKaltimtara(page);
  };
  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  React.useEffect(() => {
    // getAllTotalPengajuanKaltimtara(dispatch);
    getAllTotalPengajuanKaltimtara(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanCabangKaltimtara(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 15) {
          tmp.push(val);
        }
      });
      setDataGrafikCabangKaltimtara(tmp);
      setDataAllGrafikCabangKaltimtara(grafikCabang);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanUserKaltimtara(dispatch, {
      tahun: tahun,
    }).then((response) => {
      const grafikUser = response.data;
      setDataGrafikUserKaltimtara(grafikUser);
    });
  }, [tahun]);

  const customStylesKaltimtara = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabangPencairanKaltimtara = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) =>
        (currentPageKaltimtara - 1) * itemsPerPageKaltimtara + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalPencairan.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalTarget.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
  ];

  React.useEffect(() => {
    getAllTotalSupervisorKaltimtara(dispatch).then((response) => {
      const dataSupervisor = response.data;
      setTotalSpvKaltimtara(dataSupervisor);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalAreaManagerKaltimtara(dispatch).then((response) => {
      const dataAreaManager = response.data;
      setTotalAMKaltimtara(dataAreaManager);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalMarketingKaltimtara(dispatch).then((response) => {
      const dataMarketing = response.data;
      setTotalMarketingKaltimtara(dataMarketing);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalCabangKaltimtara(dispatch).then((response) => {
      const dataCabang = response.data;
      setTotalCbgKaltimtara(dataCabang);
    });
  }, []);

  const openCardKaltimtara = () => {
    setCardDetailKaltimtara(!cardDetailKaltimtara);
    setCardDetailKalteng(null);
  };

  const closeModalKaltimtara = () => {
    setCurrentPageKaltimtara(1);
    setModalDetailKaltimtara(false);
  };

  return (
    <>
      {/* ==================Tampilan Kaltimtara ================*/}
      <Modal
        size="lg"
        show={modalDetailKaltimtara}
        onHide={closeModalKaltimtara}
        // onHide={() => setModalDetailKaltimtara(!modalDetailKaltimtara)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Details Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairanKaltimtara}
                data={dataAllGrafikCabangKaltimtara}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStylesKaltimtara}
                pagination
                paginationPerPage={itemsPerPageKaltimtara}
                onChangePage={handlePageChangeKaltimtara}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>
      <Row>
        <Col className="mt-2">
          <h3 style={{ fontWeight: "bold" }}>DETAIL KALTIMTARA</h3>
          {/* <Row>
          <Col lg="6" sm="6">
            <Card
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: 10,
              }}
            >
              <Card.Header
                className="d-none d-md-inline"
                style={{
                  backgroundColor: "#396995",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Card.Title
                  as="h6"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  <b style={{ color: "white" }}>Total Pencairan</b>
                </Card.Title>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                {`Rp ${totalNominalPencairanKaltimtara.toLocaleString(
                  "id-ID"
                )}`}
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="6">
            <Card
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: 10,
              }}
            >
              <Card.Header
                className="d-none d-md-inline"
                style={{
                  backgroundColor: "#6AACD9",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Card.Title
                  as="h6"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  <b style={{ color: "white" }}>Total Target</b>
                </Card.Title>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                {`Rp ${totalNominalTargetKaltimtara.toLocaleString(
                  "id-ID"
                )}`}
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
          <Row>
            <Col lg="6" sm="6">
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Informasi
              </h4>
              <Row>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#396995",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Area Manager</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalAMKaltimtara.totalAm} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#396995",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Supervisor</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalSpvKaltimtara.totalSpv} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#396995",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Marketing</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketingKaltimtara.totalSm} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
                {auth.role === "admin" || auth.role === "super admin" ? (
                  <Col lg="4" sm="6">
                    <Card
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        borderRadius: 10,
                      }}
                    >
                      <Card.Header
                        className="d-none d-md-inline"
                        style={{
                          backgroundColor: "#396995",
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      >
                        <Card.Title
                          as="h6"
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                            marginBottom: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <b style={{ color: "white" }}>SM Sudah Login</b>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body style={{ textAlign: "center" }}>
                        {totalMarketingKaltimtara.totalLogin} Karyawan
                      </Card.Body>
                    </Card>
                  </Col>
                ) : null}

                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#396995",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Cabang</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalCbgKaltimtara.totalCabang} Cabang
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col lg="6" sm="6">
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Status
              </h4>
              <Row>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6AACD9",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>menunggu</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {cardTotalPengajuanKaltimtara.totalPengajuan &&
                        cardTotalPengajuanKaltimtara.totalPengajuan.data
                          .menunggu}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6AACD9",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>diajukan</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {cardTotalPengajuanKaltimtara.totalPengajuan &&
                        cardTotalPengajuanKaltimtara.totalPengajuan.data
                          .diajukan}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6AACD9",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Revisi</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {cardTotalPengajuanKaltimtara.totalPengajuan &&
                        cardTotalPengajuanKaltimtara.totalPengajuan.data.revisi}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6AACD9",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Diproses</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {cardTotalPengajuanKaltimtara.totalPengajuan &&
                        cardTotalPengajuanKaltimtara.totalPengajuan.data.proses}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6AACD9",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Disetujui</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {cardTotalPengajuanKaltimtara.totalPengajuan &&
                        cardTotalPengajuanKaltimtara.totalPengajuan.data
                          .disetujui}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#6AACD9",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Ditolak</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {cardTotalPengajuanKaltimtara.totalPengajuan &&
                        cardTotalPengajuanKaltimtara.totalPengajuan.data
                          .ditolak}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                    Cabang
                    <hr />
                  </Card.Title>
                </Card.Header>
                <Row>
                  <Col md="12">
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#87CEFA",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Pencairan cabang</label>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#7FC9AA",
                            width: 15,
                            height: 15,
                            marginLeft: 20,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Target Cabang</label>
                      </div>
                      <VictoryChart
                        height={280}
                        width={850}
                        style={{ parent: { marginLeft: "1rem" } }}
                        domainPadding={{ x: 50 }}
                      >
                        <VictoryGroup
                          offset={13}
                          colorScale={["#87CEFA", "#7FC9AA"]}
                        >
                          <VictoryBar
                            data={chartDataKaltimtara.series[0].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;
                                return {
                                  x: chartDataKaltimtara.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#87CEFA",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />

                          <VictoryBar
                            data={chartDataKaltimtara.series[1].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue =
                                //   decimalDigits && decimalDigits.length > 2
                                //     ? -5
                                //     : -10;

                                return {
                                  x: chartDataKaltimtara.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#7FC9AA",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />
                        </VictoryGroup>
                        <VictoryAxis
                          label="Nama Cabang"
                          tickLabelComponent={
                            <VictoryLabel style={{ fontSize: "10px" }} />
                          }
                          style={{
                            axisLabel: {
                              padding: 30,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          tickCount={6}
                          label="Nominal Pencairan dalam Miliar"
                          style={{
                            axisLabel: {
                              padding: 42.5,
                              fontSize: "9px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                            tickLabels: {
                              fontSize: "10px",
                              // padding: 30,
                              // textAnchor: "start",
                            },
                          }}
                          domain={
                            chartDataKaltimtara.series.some((data) =>
                              data.slice(0, 10).some((yValue) => yValue !== 0)
                            )
                              ? undefined
                              : { y: [0, 1] }
                          }
                        />
                      </VictoryChart>
                    </div>
                  </Col>

                  {/* Keterangan cabang */}
                  <Col md="12" style={{ marginTop: 30 }}>
                    <Card
                      style={{
                        border: 0,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        borderRadius: 10,
                        marginRight: 30,
                        marginLeft: 30,
                        // backgroundColor: "#FFF59D",
                        color: "black",
                        padding: 20,
                      }}
                    >
                      <div style={{ marginBottom: 20 }}>
                        <Row>
                          <Col>
                            <h4
                              className="title mt-2"
                              style={{
                                fontWeight: "bold",
                                marginLeft: 20,
                                textAlign: "start",
                              }}
                            >
                              Keterangan Nama Cabang
                            </h4>
                          </Col>
                          <Col style={{ textAlign: "end" }}>
                            <Button
                              className="mr-1"
                              // variant="primary"
                              style={{
                                marginBottom: 10,
                                fontSize: 16,
                                backgroundColor: "#00BFFF",
                                border: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                              }}
                              onClick={() =>
                                setModalDetailKaltimtara(!modalDetailKaltimtara)
                              }
                            >
                              Detail Semua Cabang
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <div
                        style={{
                          marginLeft: 20,
                          flexWrap: "wrap",
                          display: "flex",
                        }}
                      >
                        {dataGrafikCabangKaltimtara.map((cabang, index) => {
                          return (
                            <div
                              style={{
                                borderWidth: 2,
                                width: 300,
                                marginTop: 10,
                              }}
                            >
                              <Row key={index}>
                                <Col sm="1">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",

                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",

                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  </Col>
                </Row>

                {/* <ChartistGraph
              data={chartDataKaltimtara}
              type="Bar"
              options={{
                seriesBarDistance: 10,
                axisX: {
                  showGrid: false,
                },
                height: "245px",
                labelInterpolationFnc: function (value, index) {
                  return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                },
              }}
              // ...
            /> */}
              </Card>
            </Col>
          </Row>

          {/* Grafik Kaltimtara */}
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                    <span> {auth.nama} </span>
                    <hr />
                  </Card.Title>
                  <Col md={"3"}>
                    <label style={{ color: "black", fontSize: 14 }}>
                      Pilih Tahun
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      defaultValue={{ label: yearNow, value: yearNow }}
                      onChange={(value) => {
                        setTahun(value.value);
                      }}
                      options={listYear}
                    />
                  </Col>
                </Card.Header>
                <Row>
                  <Col md="12">
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#FFB6C1",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Pencairan User</label>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#CBC3E3",
                            width: 15,
                            height: 15,
                            marginLeft: 20,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Target User</label>
                      </div>
                      <VictoryChart
                        height={250}
                        width={850}
                        style={{ parent: { marginLeft: "1rem" } }}
                        domainPadding={{ x: 50 }}
                      >
                        <VictoryGroup
                          offset={12.5}
                          colorScale={["#FFB6C1", "#CBC3E3"]}
                        >
                          <VictoryBar
                            data={chartDataUserKaltimtara.series[0].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;
                                return {
                                  x: chartDataUserKaltimtara.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#FFB6C1",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />

                          <VictoryBar
                            data={chartDataUserKaltimtara.series[1].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                                return {
                                  x: chartDataUserKaltimtara.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                dy={-2}
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#CBC3E3",
                                  padding: 5,
                                }}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />
                        </VictoryGroup>
                        <VictoryAxis
                          tickLabelComponent={
                            <VictoryLabel style={{ fontSize: "10px" }} />
                          }
                          label="Bulan"
                          style={{
                            axisLabel: {
                              padding: 30,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          label="Nominal Pencairan dalam Miliar"
                          style={{
                            axisLabel: {
                              padding: 42.5,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                            tickLabels: {
                              fontSize: "10px",
                            },
                          }}
                          domain={
                            chartDataUserKaltimtara.series.some((data) =>
                              data.some((yValue) => yValue !== 0)
                            )
                              ? undefined
                              : { y: [0, 1] }
                          }
                        />
                      </VictoryChart>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default DetailKaltimtara;
