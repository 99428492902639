/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import PrivacyPolicy from "views/PrivacyPolicy";
import TermsConditions from "views/TermsConditions";
// import SettingGedungPage from "views/Pages/bpdKaltimtara/PatroliKebersihan/SettingGedung";
// import SettingShiftPage from "views/Pages/bpdKaltimtara/PatroliKebersihan/SettingShift";
// import RekapMonitoringPage from "views/Pages/bpdKaltimtara/PatroliKebersihan/RekapLaporan";
// import DashboardMonitoringPage from "views/Pages/bpdKaltimtara/PatroliKebersihan/DashboardMonitoring";
import DashboardUtama from "views/DashboardUtama";

//View BPD Kaltimtara
import ListClientKaltimtara from "views/Pages/bpdKaltimtara/Client/ListClient";
import ListUserKaltimtara from "views/Pages/bpdKaltimtara/User/TabUsers/IndexUser";
import ListCabangKaltimtara from "views/Pages/bpdKaltimtara/Cabang/ListCabang";
import PengajuanKaltimtara from "views/Pages/bpdKaltimtara/Pengajuan/Pengajuan";
import PencairanKaltimtara from "views/Pages/bpdKaltimtara/Pencairan/Pencairan";
import TargetKorwilKaltimtara from "views/Pages/bpdKaltimtara/Target/TargetKorwil/TargetKorwil";
import TargetAreaManagerKaltimtara from "views/Pages/bpdKaltimtara/Target/TargetAreaManager/TargetAreaManager";
import TargetSupervisorKaltimtara from "views/Pages/bpdKaltimtara/Target/TargetSupervisor/TargetSupervisor";
import TargetMarketingKaltimtara from "views/Pages/bpdKaltimtara/Target/TargetMarketing/TargetMarketing";
import TargetCabangKaltimtara from "views/Pages/bpdKaltimtara/Target/TargetCabang/TargetCabang";
import UserProfileKaltimtara from "views/Pages/bpdKaltimtara/User/UserProfile";
import PenggajianUserKaltimtara from "views/Pages/bpdKaltimtara/PenggajianUser/indexPenggajianUser";

//View BPD Kalteng
import ListClientKalteng from "views/Pages/bpdKalteng/Client/ListClient";
import ListUserKalteng from "views/Pages/bpdKalteng/User/TabUsers/IndexUser";
import ListCabangKalteng from "views/Pages/bpdKalteng/Cabang/ListCabang";
import PengajuanKalteng from "views/Pages/bpdKalteng/Pengajuan/Pengajuan";
import PencairanKalteng from "views/Pages/bpdKalteng/Pencairan/Pencairan";
import TargetKorwilKalteng from "views/Pages/bpdKalteng/Target/TargetKorwil/TargetKorwil";
import TargetAreaManagerKalteng from "views/Pages/bpdKalteng/Target/TargetAreaManager/TargetAreaManager";
import TargetSupervisorKalteng from "views/Pages/bpdKalteng/Target/TargetSupervisor/TargetSupervisor";
import TargetMarketingKalteng from "views/Pages/bpdKalteng/Target/TargetMarketing/TargetMarketing";
import TargetCabangKalteng from "views/Pages/bpdKalteng/Target/TargetCabang/TargetCabang";
import UserProfileKalteng from "views/Pages/bpdKalteng/User/UserProfile";

//View BPD Sulutgo
import ListClientSulutgo from "views/Pages/bpdSulutgo/Client/ListClient";
import ListUserSulutgo from "views/Pages/bpdSulutgo/User/TabUsers/IndexUser";
import ListCabangSulutgo from "views/Pages/bpdSulutgo/Cabang/ListCabang";
import PengajuanSulutgo from "views/Pages/bpdSulutgo/Pengajuan/Pengajuan";
import PencairanSulutgo from "views/Pages/bpdSulutgo/Pencairan/Pencairan";
import TargetKorwilSulutgo from "views/Pages/bpdSulutgo/Target/TargetKorwil/TargetKorwil";
import TargetAreaManagerSulutgo from "views/Pages/bpdSulutgo/Target/TargetAreaManager/TargetAreaManager";
import TargetSupervisorSulutgo from "views/Pages/bpdSulutgo/Target/TargetSupervisor/TargetSupervisor";
import TargetMarketingSulutgo from "views/Pages/bpdSulutgo/Target/TargetMarketing/TargetMarketing";
import TargetCabangSulutgo from "views/Pages/bpdSulutgo/Target/TargetCabang/TargetCabang";
import UserProfileSulutgo from "views/Pages/bpdSulutgo/User/UserProfile";

var routes = [
  // {
  //   path: '/dashboard',
  //   layout: '/admin',
  //   name: 'Dashboard',
  //   icon: 'nc-icon nc-chart-pie-35',
  //   component: Dashboard,
  // },
  {
    show: true,
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35 icon-bold",
    component: DashboardUtama,
  },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/kaltim",
  //   name: "BPD Kaltimtara",
  //   state: "openBPDKaltimtara",
  //   icon: "fas fa-building",
  //   views: [
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/client",
  //       name: "Client",
  //       state: "openClientKaltimtara",
  //       icon: "nc-icon nc-bank icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/listClient",
  //           layout: "/admin",
  //           name: "List Client",
  //           component: ListClientKaltimtara,
  //         },
  //         // {
  //         //   path: '/targetClient',
  //         //   layout: '/admin',
  //         //   name: 'Target Client',
  //         //   component: TargetClient,
  //         // },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/user",
  //       name: "User",
  //       state: "openUserKaltimtara",
  //       icon: "nc-icon nc-circle-09 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/listUser",
  //           layout: "/admin",
  //           name: "List User",
  //           component: ListUserKaltimtara,
  //         },
  //         // {
  //         //   path: "/targetUser",
  //         //   layout: "/admin",
  //         //   name: "Target User",
  //         //   component: TargetUser,
  //         // },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/cabang",
  //       name: "Cabang",
  //       state: "openCabangKaltimtara",
  //       icon: "nc-icon nc-pin-3 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/ListCabang",
  //           layout: "/admin",
  //           name: "List Cabang",
  //           component: ListCabangKaltimtara,
  //         },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/target",
  //       name: "Target",
  //       state: "openTargetKaltimtara",
  //       icon: "nc-icon nc-chart-bar-32 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/TargetKorwil",
  //           layout: "/admin",
  //           name: "Target Korwil",
  //           component: TargetKorwilKaltimtara,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetAreaManager",
  //           layout: "/admin",
  //           name: "Target Area Manager",
  //           component: TargetAreaManagerKaltimtara,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetSupervisor",
  //           layout: "/admin",
  //           name: "Target Supervisor",
  //           component: TargetSupervisorKaltimtara,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetMarketing",
  //           layout: "/admin",
  //           name: "Target Marketing",
  //           component: TargetMarketingKaltimtara,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetCabang",
  //           layout: "/admin",
  //           name: "Target Cabang",
  //           component: TargetCabangKaltimtara,
  //         },
  //       ],
  //     },

  //     {
  //       show: true,
  //       path: "/pengajuan",
  //       layout: "/admin",
  //       name: "Pengajuan",
  //       icon: "nc-icon nc-single-copy-04 icon-bold",
  //       component: PengajuanKaltimtara,
  //     },
  //     {
  //       show: true,
  //       path: "/pencairan",
  //       layout: "/admin",
  //       name: "Pencairan",
  //       icon: "nc-icon nc-money-coins icon-bold",
  //       component: PencairanKaltimtara,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/client",
  //   name: "BPD Kalteng",
  //   state: "openBPDKalteng",
  //   icon: "fas fa-building",
  //   views: [
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/clientKalteng",
  //       name: "Client",
  //       state: "openClientKalteng",
  //       icon: "nc-icon nc-bank icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/listClientKalteng",
  //           layout: "/admin",
  //           name: "List Client",
  //           component: ListClientKalteng,
  //         },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/userKalteng",
  //       name: "User",
  //       state: "openUserKalteng",
  //       icon: "nc-icon nc-circle-09 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/listUserKalteng",
  //           layout: "/admin",
  //           name: "List User",
  //           component: ListUserKalteng,
  //         },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/cabangKalteng",
  //       name: "Cabang",
  //       state: "openCabangKalteng",
  //       icon: "nc-icon nc-pin-3 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/ListCabangKalteng",
  //           layout: "/admin",
  //           name: "List Cabang",
  //           component: ListCabangKalteng,
  //         },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/targetKalteng",
  //       name: "Target",
  //       state: "openTargetKalteng",
  //       icon: "nc-icon nc-chart-bar-32 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/TargetKorwilKalteng",
  //           layout: "/admin",
  //           name: "Target Korwil",
  //           component: TargetKorwilKalteng,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetAreaManagerKalteng",
  //           layout: "/admin",
  //           name: "Target Area Manager",
  //           component: TargetAreaManagerKalteng,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetSupervisorKalteng",
  //           layout: "/admin",
  //           name: "Target Supervisor",
  //           component: TargetSupervisorKalteng,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetMarketingKalteng",
  //           layout: "/admin",
  //           name: "Target Marketing",
  //           component: TargetMarketingKalteng,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetCabangKalteng",
  //           layout: "/admin",
  //           name: "Target Cabang",
  //           component: TargetCabangKalteng,
  //         },
  //       ],
  //     },

  //     {
  //       show: true,
  //       path: "/pengajuanKalteng",
  //       layout: "/admin",
  //       name: "Pengajuan",
  //       icon: "nc-icon nc-single-copy-04 icon-bold",
  //       component: PengajuanKalteng,
  //     },
  //     {
  //       show: true,
  //       path: "/pencairanKalteng",
  //       layout: "/admin",
  //       name: "Pencairan",
  //       icon: "nc-icon nc-money-coins icon-bold",
  //       component: PencairanKalteng,
  //     },
  //     {
  //       show: false,
  //       path: "/userprofileKalteng",
  //       layout: "/admin",
  //       name: "My Profile",
  //       icon: "far fa-user",
  //       component: UserProfileKalteng,
  //     },
  //   ],
  // },

  // {
  //   show: true,
  //   collapse: true,
  //   path: "/sulutgo",
  //   name: "BPD Sulutgo",
  //   state: "openBPDSulutgo",
  //   icon: "fas fa-building",
  //   views: [
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/clientSulutgo",
  //       name: "Client",
  //       state: "openClientSulutgo",
  //       icon: "nc-icon nc-bank icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/listClientSulutgo",
  //           layout: "/admin",
  //           name: "List Client",
  //           component: ListClientSulutgo,
  //         },
  //         // {
  //         //   path: '/targetClient',
  //         //   layout: '/admin',
  //         //   name: 'Target Client',
  //         //   component: TargetClient,
  //         // },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/userSulutgo",
  //       name: "User",
  //       state: "openUserSulutgo",
  //       icon: "nc-icon nc-circle-09 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/listUserSulutgo",
  //           layout: "/admin",
  //           name: "List User",
  //           component: ListUserSulutgo,
  //         },
  //         // {
  //         //   path: "/targetUser",
  //         //   layout: "/admin",
  //         //   name: "Target User",
  //         //   component: TargetUser,
  //         // },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/cabangSulutgo",
  //       name: "Cabang",
  //       state: "openCabangSulutgo",
  //       icon: "nc-icon nc-pin-3 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/ListCabangSulutgo",
  //           layout: "/admin",
  //           name: "List Cabang",
  //           component: ListCabangSulutgo,
  //         },
  //       ],
  //     },
  //     {
  //       show: true,
  //       collapse: true,
  //       path: "/targetSulutgo",
  //       name: "Target",
  //       state: "openTargetSulutgo",
  //       icon: "nc-icon nc-chart-bar-32 icon-bold",
  //       views: [
  //         {
  //           show: true,
  //           path: "/TargetKorwilSulutgo",
  //           layout: "/admin",
  //           name: "Target Korwil",
  //           component: TargetKorwilSulutgo,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetAreaManagerSulutgo",
  //           layout: "/admin",
  //           name: "Target Area Manager",
  //           component: TargetAreaManagerSulutgo,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetSupervisorSulutgo",
  //           layout: "/admin",
  //           name: "Target Supervisor",
  //           component: TargetSupervisorSulutgo,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetMarketingSulutgo",
  //           layout: "/admin",
  //           name: "Target Marketing",
  //           component: TargetMarketingSulutgo,
  //         },
  //         {
  //           show: true,
  //           path: "/TargetCabangSulutgo",
  //           layout: "/admin",
  //           name: "Target Cabang",
  //           component: TargetCabangSulutgo,
  //         },
  //       ],
  //     },
  //     {
  //       show: true,
  //       path: "/pengajuanSulutgo",
  //       layout: "/admin",
  //       name: "Pengajuan",
  //       icon: "nc-icon nc-single-copy-04 icon-bold",
  //       component: PengajuanSulutgo,
  //     },
  //     {
  //       show: true,
  //       path: "/pencairanSulutgo",
  //       layout: "/admin",
  //       name: "Pencairan",
  //       icon: "nc-icon nc-money-coins icon-bold",
  //       component: PencairanSulutgo,
  //     },
  //   ],
  // },
  // {
  //   show: false,
  //   path: "/userprofileKalteng",
  //   layout: "/admin",
  //   name: "My Profile",
  //   icon: "far fa-user",
  //   component: UserProfile,
  // },

  //Template Awal
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       show: true,
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       show: true,
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       show: true,
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       show: true,
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       show: true,
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  // {
  //   show: true,
  //   path: "/icons",
  //   layout: "/admin",
  //   name: "Icons",
  //   mini: "I",
  //   component: Icons,
  // },
  //     {
  //       show: true,
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //     },
  //     {
  //       show: true,
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //     },
  //     {
  //       show: true,
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       show: true,
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   show: true,
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  //Template Akhir
  {
    show: false,
    path: "/login-page",
    layout: "/auth",
    name: "Login Page",
    mini: "LP",
    component: LoginPage,
  },
  {
    show: false,
    path: "/privacy",
    layout: "/auth",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  {
    show: false,
    path: "/termsandconditions",
    layout: "/auth",
    name: "Terms & Conditions",
    component: TermsConditions,
    target: "_blank",
    rel: "noopener noreferrer",
  },
  // {
  //   show: false,
  //   collapse: true,
  //   path: "/pages",
  //   name: "Pages",
  //   state: "openPages",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [
  //     // {
  //     //   path: '/user-page',
  //     //   layout: '/admin',
  //     //   name: 'User Page',
  //     //   mini: 'UP',
  //     //   component: UserPage,
  //     // },
  //     {
  //       show: false,
  //       path: "/login-page",
  //       layout: "/auth",
  //       name: "Login Page",
  //       mini: "LP",
  //       component: LoginPage,
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   path: '/monitoringkebersihan',
  //   name: 'Monitoring',
  //   state: 'openKebersihan',
  //   icon: 'nc-icon nc-puzzle-10',
  //   views: [
  //     {
  //       path: '/dashboardmonitoring',
  //       layout: '/admin',
  //       name: 'Dashboard',
  //       mini: 'D',
  //       component: DashboardMonitoringPage,
  //     },
  //     {
  //       path: '/rekapmonitoring',
  //       layout: '/admin',
  //       name: 'Rekap Monitoring',
  //       mini: 'RM',
  //       component: RekapMonitoringPage,
  //     },
  //     {
  //       path: '/settingarea',
  //       layout: '/admin',
  //       name: 'Setting Area',
  //       mini: 'SA',
  //       component: SettingGedungPage,
  //     },
  //     {
  //       path: '/settingshift',
  //       layout: '/admin',
  //       name: 'Setting Shift',
  //       mini: 'SS',
  //       component: SettingShiftPage,
  //     },
  //   ],
  // },
];
export default routes;
