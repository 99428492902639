import { baseAxiosSulutgo } from "../../index";

export async function getAllPengajuanSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    // console.log(response, "PENGAJUANNNNNNNNNNNNNNNNNNNn");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPencairanSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPencairanSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.post(
      "webDashboard/getTotalPencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTargetUserSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.post(
      "webDashboard/getTargetUserLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

// export async function revisiPengajuan(data) {
//   try {
//     const response = await baseAxiosSulutgo.post(
//       "webPengajuan/revisiPengajuanDariAtasan",
//       data,
//       {
//         headers: {
//           token: localStorage.getItem("token"),
//         },
//       }
//     );
//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }

// export async function pengajuanBPD(data) {
//   try {
//     const response = await baseAxiosSulutgo.post("webApiBpd/pengajuan", data, {
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     });
//     // console.log(response, "DARI AXIOSSSSSS");
//     return response;
//   } catch (err) {
//     return err.response;
//   }
// }

export async function updateStatusPengajuanSulutgo(data) {
  try {
    const response = await baseAxiosSulutgo.post(
      "webPengajuan/updateStatusPengajuanManual",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    // console.log(response, "DARI AXIOSSSSSS");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function jsonPengajuanSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.get(`mobileListJson/allJson`, {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "JSON_PENGAJUAN", data: response.data });
    // console.log(response.data, "JSONNNNNNN");
    return response.status;
  } catch (err) {
    // console.log(err, "JSON GAGAL");
    return err.response;
  }
}
