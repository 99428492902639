import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
// import { getAllProfileKaltimtara } from "stores/bpdKaltimtara";
import { getAllProfileKaltimtara } from "stores";

function Sidebar({ routes, image, background }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const [foto, setFoto] = React.useState("");
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the user collapse
  const [userCollapseState, setUserCollapseState] = React.useState(false);
  const [modalProfile, setModalProfile] = React.useState(false);
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});

  const handleClick = (e) => {
    e.preventDefault();
    history.push("/admin/userprofile"); // Ganti dengan path yang sesuai
  };

  React.useEffect(() => {
    ambilFoto();
  }, [auth.kodeUser]);

  const ambilFoto = () => {
    getAllProfileKaltimtara(dispatch, {
      kodeUser: auth.kodeUser,
    })
      .then((response) => {
        let fotoProfil = response.data.data.fotoProfile;
        setFoto(fotoProfil);
        // setFoto(fotoProfil);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkHakAkses = (nama) => {
    for (let index = 0; index < auth.menu.length; index++) {
      if (auth.menu[index] === nama) {
        return true;
      }
    }
    return false;
  };
  React.useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes prop
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}></i>
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }

      if (prop.show) {
        return (
          <Nav.Item
            className={activeRoute(prop.layout + prop.path)}
            key={key}
            as="li"
          >
            <Nav.Link to={prop.layout + prop.path} as={Link}>
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </Nav.Link>
          </Nav.Item>
        );
      }
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  return (
    <>
      {/* <Modal
        size="lg"
        show={modalProfile}
        onHide={() => {
          setModalProfile(false);
          // setOldPass("");
          // setNewPass("");
          // setConfirmPass("");
          // setPasswordMatch(true);
          // setShowNewPass(false);
          // setShowConfirmPass(false);
        }}
        // onHide={() => setModalUbahPassword(!modalUbahPassword)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Container fluid>
            <div
              className="section-image"
              data-image="../../assets/img/bg5.jpg"
            >
              <Row>
                <Col md="8">
                  <Form action="" className="form" method="">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                          Edit Profile
                          <hr></hr>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col className="pr-1" md="5">
                            <Form.Group>
                              <label>Company (disabled)</label>
                              <Form.Control
                                defaultValue="Creative Code Inc."
                                disabled
                                placeholder="Company"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="3">
                            <Form.Group>
                              <label>Username</label>
                              <Form.Control
                                defaultValue="michael23"
                                placeholder="Username"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <Form.Control
                                placeholder="Email"
                                type="email"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="6">
                            <Form.Group>
                              <label>First Name</label>
                              <Form.Control
                                defaultValue="Mike"
                                placeholder="Company"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="6">
                            <Form.Group>
                              <label>Last Name</label>
                              <Form.Control
                                defaultValue="Andrew"
                                placeholder="Last Name"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>Address</label>
                              <Form.Control
                                defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                placeholder="Home Address"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pr-1" md="4">
                            <Form.Group>
                              <label>City</label>
                              <Form.Control
                                defaultValue="Mike"
                                placeholder="City"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="px-1" md="4">
                            <Form.Group>
                              <label>Country</label>
                              <Form.Control
                                defaultValue="Andrew"
                                placeholder="Country"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col className="pl-1" md="4">
                            <Form.Group>
                              <label>Postal Code</label>
                              <Form.Control
                                placeholder="ZIP Code"
                                type="number"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <label>About Me</label>
                              <Form.Control
                                cols="80"
                                defaultValue="Mike"
                                placeholder="Here can be your description"
                                rows="4"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                        >
                          Update Profile
                        </Button>
                        <div className="clearfix"></div>
                      </Card.Body>
                    </Card>
                  </Form>
                </Col>
                <Col md="4">
                  <Card className="card-user">
                    <Card.Header className="no-padding">
                      <div className="card-image">
                        <img
                          alt="..."
                          src={
                            require("assets/img/full-screen-image-3.jpg")
                              .default
                          }
                        ></img>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="author">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar border-gray"
                            src={
                              require("assets/img/default-avatar.png").default
                            }
                          ></img>
                          <Card.Title as="h5">Tania Keatley</Card.Title>
                        </a>
                        <p className="card-description">michael24</p>
                      </div>
                      <p className="card-description text-center">
                        Hey there! As you can see, <br></br>
                        it is already looking great.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
      </Modal> */}

      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            {/* <a
              className="simple-text logo-mini"
              href="http://www.creative-tim.com"
            >
              <div className="logo-img">
                <img
                  src={require("assets/img/logo.svg").default}
                  alt="react-logo"
                />
              </div>
            </a> */}
            {/* <div
              className="simple-text logo-normal"
              style={{ marginLeft: 25 }}
              // href="http://www.creative-tim.com"
            >
              {auth.perusahaan}
              Bina Area Persada
            </div> */}
          </div>
          <div className="user">
            <div className="photo">
              <img
                alt="..."
                src={
                  foto
                    ? `${foto}?${new Date().getTime()}`
                    : require("assets/img/defaultUser.png").default
                }
              ></img>
            </div>
            <div className="info">
              <a
                // className={userCollapseState ? "collapsed" : ""}
                data-toggle="collapse"
                href="#pablo"
                onClick={handleClick}
                // onClick={(e) => {
                //   e.preventDefault();
                //   // setModalProfile(!modalProfile);
                // }}
                // aria-expanded={userCollapseState}
              >
                <span>{auth.nama}</span>
              </a>
              {/* <Collapse id="collapseExample" in={userCollapseState}>
                <div>
                  <Nav as="ul">
                    <li>
                      <a
                        className="profile-dropdown"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span
                          className="sidebar-mini"
                          style={{ marginTop: 10 }}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/usernav.png").default}
                            height="20PX"
                            style={{ marginBottom: 5 }}
                          ></img>
                        </span>
                        <span
                          className="sidebar-normal"
                          style={{ marginTop: 8 }}
                        >
                          My Profile
                        </span>
                      </a>
                    </li>
                  </Nav>
                </div>
              </Collapse> */}
            </div>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url('" + image + "')",
          }}
        ></div>
      </div>
    </>
  );
}

let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: [],
};

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf([
    "black",
    "azure",
    "green",
    "orange",
    "red",
    "purple",
  ]),
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string,
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string,
        }),
      }),
    ])
  ),
};

export default Sidebar;
