import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Nav,
  Tab,
} from "react-bootstrap";
import { Label } from "reactstrap";

import ReactDatetime from "react-datetime";

import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import DetailKaltimtara from "./DashboardDetailKaltimtara";
import DetailKalsel from "./DashboardDetailKalsel";
import DetailKalteng from "./DashboardDetailKalteng";
import DetailSulutgo from "./DashboardDetailSulutgo";
// import { getTotalTargetUserKaltimtara } from "stores/bpdKaltimtara/Pengajuan/function";
// import { getGrafikPencairanUserKaltimtara } from "stores";
// import { getAllTotalSupervisorKaltimtara } from "stores";
// import { getAllTotalMarketingKaltimtara } from "stores";
// import { getAllTotalCabangKaltimtara } from "stores";

//=======================KALTIMTARA==========================

import { getTotalPencairanKaltimtara } from "stores/bpdKaltimtara/Pengajuan/function";
import { getTotalTargetUserKaltimtara } from "stores/bpdKaltimtara/Pengajuan/function";
// import { getGrafikPencairanUserKaltimtara } from "stores/bpdKaltimtara/dashboardUtama/function";

//======================KALTENG=============================
import { getTotalTargetUserKalteng } from "stores/bpdKalteng/Pengajuan/function";
import { getTotalPencairanKalteng } from "stores/bpdKalteng/Pengajuan/function";

//=====================SULUTGO==============================
import { getTotalPencairanSulutgo } from "stores/bpdSulutgo/Pengajuan/function";
import { getTotalTargetUserSulutgo } from "stores/bpdSulutgo/Pengajuan/function";

//=====================KALSEL==============================
import { getTotalPencairanKalsel } from "stores/bpdKalsel/Pengajuan/function";
import { getTotalTargetUserKalsel } from "stores/bpdKalsel/Pengajuan/function";

function DashboardUtama() {
  const [openKaltim, setOpenKaltim] = React.useState(false);
  const [openKalsel, setOpenKalsel] = React.useState(false);
  const [openKalteng, setOpenKalteng] = React.useState(false);
  const [openSulutgo, setOpenSulutgo] = React.useState(false);
  const [openKaltimCard, setOpenKaltimCard] = React.useState(true);
  const [openKalselCard, setOpenKalselCard] = React.useState(true);
  const [openKaltengCard, setOpenKaltengCard] = React.useState(true);
  const [openSulutgoCard, setOpenSulutgoCard] = React.useState(true);
  //=========================KALTIMTARA=============================
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const cardTotalAreaManagerKaltimtara = useSelector(
    (state) => state.dashboardUtamaReducerKaltimtara
  );
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducer);
  // console.log(cardTotalPencairan.data, "Dari Store");

  const [dataGrafikUserKaltimtara, setDataGrafikUserKaltimtara] =
    React.useState([]);
  const totalTargetKaltimtara = hasilPresentaseKaltimtara > 100;
  const [totalNominalPencairanKaltimtara, setTotalNominalPencairanKaltimtara] =
    React.useState(0);
  const [hasilPresentaseKaltimtara, setHasilPresentaseKaltimtara] =
    React.useState(0);
  const [totalNominalTargetKaltimtara, setTotalNominalTargetKaltimtara] =
    React.useState(0);

  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );

  const [smpTgl, setSmpTgl] = React.useState(new Date());

  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);

  const [cardDetailKaltimtara, setCardDetailKaltimtara] = React.useState(false);
  const bulanData = dataGrafikUserKaltimtara.map((item) => item.bulan);

  //========================KALTENG========================================
  const totalTargetKalteng = hasilPresentaseKalteng > 100;

  const [totalNominalTargetKalteng, setTotalNominalTargetKalteng] =
    React.useState(0);

  const [hasilPresentaseKalteng, setHasilPresentaseKalteng] = React.useState(0);
  const [totalNominalPencairanKalteng, setTotalNominalPencairanKalteng] =
    React.useState(0);

  //========================SULUTGO======================================
  const cardTotalAreaManagerSulutgo = useSelector(
    (state) => state.dashboardUtamaReducerSulutgo
  );
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducerSulutgo);
  // console.log(cardTotalPencairan.data, "Dari Store");
  const cardTotalPengajuanSulutgo = useSelector(
    (state) => state.dashboardUtamaReducerSulutgo
  );
  const [totalNominalTargetSulutgo, setTotalNominalTargetSulutgo] =
    React.useState(0);
  const [hasilPresentaseSulutgo, setHasilPresentaseSulutgo] = React.useState(0);
  const [totalNominalPencairanSulutgo, setTotalNominalPencairanSulutgo] =
    React.useState(0);

  //=============================KALSEL=================================
  const [totalNominalPencairanKalsel, setTotalNominalPencairanKalsel] =
    React.useState(0);
  const [hasilPresentaseKalsel, setHasilPresentaseKalsel] = React.useState(0);
  const [totalNominalTargetKalsel, setTotalNominalTargetKalsel] =
    React.useState(0);
  //=======================================================================
  //======================KALTIMTARA========================================
  React.useEffect(() => {
    if (
      totalNominalTargetKaltimtara !== 0 &&
      totalNominalPencairanKaltimtara !== 0
    ) {
      const presentase = Math.round(
        (totalNominalPencairanKaltimtara / totalNominalTargetKaltimtara) * 100
      );
      setHasilPresentaseKaltimtara(presentase);
    }
  }, [totalNominalPencairanKaltimtara, totalNominalTargetKaltimtara]);

  React.useEffect(() => {
    getTotalPencairanKaltimtara(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairanKaltimtara(totalPencairan);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalTargetUserKaltimtara(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTargetKaltimtara = response.data.totalTarget;
      setTotalNominalTargetKaltimtara(totalTargetKaltimtara);
    });
  }, [dariTgl, smpTgl]);

  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };
  //==========================KALTENG=====================================
  React.useEffect(() => {
    if (totalNominalPencairanKalteng !== 0 && totalNominalTargetKalteng !== 0) {
      const presentase = Math.round(
        (totalNominalPencairanKalteng / totalNominalTargetKalteng) * 100
      );
      setHasilPresentaseKalteng(presentase);
    } else {
      setHasilPresentaseKalteng(0);
    }
  }, [totalNominalPencairanKalteng, totalNominalTargetKalteng]);

  React.useEffect(() => {
    getTotalTargetUserKalteng(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTargetKalteng(totalTarget);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalPencairanKalteng(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairanKalteng(totalPencairan);
    });
  }, [dariTgl, smpTgl]);
  //===================================SULUTGO==============================

  React.useEffect(() => {
    if (totalNominalPencairanSulutgo !== 0 && totalNominalTargetSulutgo !== 0) {
      const presentase = Math.round(
        (totalNominalPencairanSulutgo / totalNominalTargetSulutgo) * 100
      );
      setHasilPresentaseSulutgo(presentase);
    } else {
      setHasilPresentaseSulutgo(0);
    }
  }, [totalNominalPencairanSulutgo, totalNominalTargetSulutgo]);
  React.useEffect(() => {
    getTotalTargetUserSulutgo(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTargetSulutgo(totalTarget);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalPencairanSulutgo(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairanSulutgo(totalPencairan);
    });
  }, [dariTgl, smpTgl]);

  //===========================KALSEL=================================
  React.useEffect(() => {
    if (totalNominalTargetKalsel !== 0 && totalNominalPencairanKalsel !== 0) {
      const presentaseKalsel = Math.round(
        (totalNominalPencairanKalsel / totalNominalTargetKalsel) * 100
      );
      setHasilPresentaseKalsel(presentaseKalsel);
    } else {
      setHasilPresentaseKalsel(0);
    }
  }, [totalNominalPencairanKalsel, totalNominalTargetKalsel]);

  React.useEffect(() => {
    getTotalPencairanKalsel(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairanKalsel = response.data.totalNominal;
      setTotalNominalPencairanKalsel(totalPencairanKalsel);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalTargetUserKalsel(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTargetKalsel = response.data.totalTarget;
      setTotalNominalTargetKalsel(totalTargetKalsel);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    if (auth.role === "super admin") {
      const interval = setInterval(() => {
        window.location.reload();
        refresh();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [auth.role]);
  return (
    <>
      <Card>
        <Container fluid>
          <Row>
            <Col
              md="4"
              className="p-2 d-flex justify-content-center align-items-center"
            >
              <Image
                src={"https://ptbap.net/assets/images/logo/bap.png"}
                width="auto"
                height="100px"
              />
            </Col>
            <Col md="8" className=" d-flex justify-content-end">
              <Row className="mb-3 d-flex">
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Awal
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(0, 0, 0, 0),
                          setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                      }}
                      value={dariTgl}
                      initialValue={dariTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Akhir
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(23, 59, 0, 0),
                          setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                      }}
                      value={smpTgl}
                      initialValue={smpTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            {/* ===================CARD KALTIMTARA======================== */}
            {openKaltimCard ? (
              <Col md={!openKaltim ? "6" : "12"}>
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: 10,
                    // marginTop: 15,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline "
                    style={{
                      backgroundColor: "#569DAA",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title>
                      <Row className="justify-content-end  d-flex">
                        <Col
                          className="p-2"
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            marginLeft: 10,
                          }}
                        >
                          <b style={{ color: "white" }}>bpd kaltimtara</b>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {!openKaltim ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Detail Kaltimtara</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "#EBF5F7",
                                  marginBottom: 15,
                                  fontSize: 16,
                                  border: 0,
                                  color: "#569DAA",
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(true);
                                  setOpenKalsel(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(false);
                                  setOpenKaltimCard(true);
                                  setOpenKalselCard(false);
                                  setOpenKaltengCard(false);
                                  setOpenSulutgoCard(false);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Detail
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Tutup Detail</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "red",
                                  marginBottom: 15,
                                  border: 0,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalsel(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(false);
                                  setOpenKaltimCard(true);
                                  setOpenKalselCard(true);
                                  setOpenKaltengCard(true);
                                  setOpenSulutgoCard(true);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Tutup Detail
                              </Button>
                            </OverlayTrigger>
                          )}
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>

                  {/* ====================Tampilan Card Body=================== */}
                  {!openKaltim ? (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={
                              "https://mpp.samarindakota.go.id/storage/media_pro/63/6.png"
                            }
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKaltimtara > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseKaltimtara} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <b
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Total Pencairan
                              </b>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    totalNominalPencairanKaltimtara >
                                    totalNominalTargetKaltimtara
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalPencairanKaltimtara.toLocaleString(
                                    "id-ID"
                                  )}`}{" "}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category ">
                                <b
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                  }}
                                >
                                  Total Target
                                </b>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalTargetKaltimtara.toLocaleString(
                                    "id-ID"
                                  )}`}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  ) : (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={
                              "https://mpp.samarindakota.go.id/storage/media_pro/63/6.png"
                            }
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKaltimtara > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseKaltimtara} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                          <Row className="mt-3">
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Total Pencairan
                                  </b>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color:
                                        totalNominalPencairanKaltimtara >
                                        totalNominalTargetKaltimtara
                                          ? "#26DB53"
                                          : "#FF4D4F",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalPencairanKaltimtara.toLocaleString(
                                        "id-ID"
                                      )}`}{" "}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <p className="card-category ">
                                    <b
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                      }}
                                    >
                                      Total Target
                                    </b>
                                  </p>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color: "#26DB53",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalTargetKaltimtara.toLocaleString(
                                        "id-ID"
                                      )}`}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ) : null}

            {/* ===================CARD KALTENG======================== */}
            {openKaltengCard ? (
              <Col md={!openKalteng ? "6" : "12"}>
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#0A2647",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title>
                      <Row>
                        <Col
                          className="p-2 align-items-center "
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            marginLeft: 10,
                          }}
                        >
                          <b style={{ color: "white" }}>bpd kalteng</b>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {!openKalteng ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Detail Kalteng</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "#D8E2ED",
                                  marginBottom: 15,
                                  color: "#0A2647",
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  border: 0,
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalteng(true);
                                  setOpenKalsel(false);
                                  setOpenSulutgo(false);
                                  setOpenKaltimCard(false);
                                  setOpenKaltengCard(true);
                                  setOpenKalselCard(false);
                                  setOpenSulutgoCard(false);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Detail
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Tutup Detail</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "red",
                                  marginBottom: 15,
                                  border: 0,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalsel(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(false);
                                  setOpenKaltimCard(true);
                                  setOpenKalselCard(true);
                                  setOpenKaltengCard(true);
                                  setOpenSulutgoCard(true);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Tutup Detail
                              </Button>
                            </OverlayTrigger>
                          )}
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>
                  {/* ====================Card Body==================== */}
                  {!openKalteng ? (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={
                              "https://play-lh.googleusercontent.com/HPc-FLGZnSwC2E4Tvkt97h7vUvyVtoJixG4tEn3ZQhjpqhr3s8_NGugb0qpijxEURw=w240-h480-rw"
                            }
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKalteng > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseKalteng} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <b
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Total Pencairan
                              </b>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    totalNominalPencairanKalteng >
                                    totalNominalTargetKalteng
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalPencairanKalteng.toLocaleString(
                                    "id-ID"
                                  )}`}{" "}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category ">
                                <b
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                  }}
                                >
                                  Total Target
                                </b>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalTargetKalteng.toLocaleString(
                                    "id-ID"
                                  )}`}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  ) : (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={
                              "https://play-lh.googleusercontent.com/HPc-FLGZnSwC2E4Tvkt97h7vUvyVtoJixG4tEn3ZQhjpqhr3s8_NGugb0qpijxEURw=w240-h480-rw"
                            }
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKalteng > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseKalteng} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                          <Row className="mt-3">
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Total Pencairan
                                  </b>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color:
                                        totalNominalPencairanKalteng >
                                        totalNominalTargetKalteng
                                          ? "#26DB53"
                                          : "#FF4D4F",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalPencairanKalteng.toLocaleString(
                                        "id-ID"
                                      )}`}{" "}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <p className="card-category ">
                                    <b
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                      }}
                                    >
                                      Total Target
                                    </b>
                                  </p>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color: "#26DB53",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalTargetKalteng.toLocaleString(
                                        "id-ID"
                                      )}`}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ) : null}
            {/* ===================CARD SULLUTGO======================== */}
            {openSulutgoCard ? (
              <Col md={!openSulutgo ? "6" : "12"}>
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#825F87",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title>
                      <Row>
                        <Col
                          className="p-2 align-items-center "
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            marginLeft: 10,
                          }}
                        >
                          <b style={{ color: "white" }}>bpd sulutgo</b>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {!openSulutgo ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Detail Sulutgo</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "#F2ECF3",
                                  marginBottom: 15,
                                  fontWeight: "bold",
                                  fontSize: 16,
                                  color: "#825F87",
                                  border: 0,
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(true);
                                  setOpenKalsel(false);
                                  setOpenKaltimCard(false);
                                  setOpenKaltengCard(false);
                                  setOpenSulutgoCard(true);
                                  setOpenKalselCard(false);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Detail
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Tutup Detail</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "red",
                                  marginBottom: 15,
                                  border: 0,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalsel(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(false);
                                  setOpenKaltimCard(true);
                                  setOpenKalselCard(true);
                                  setOpenKaltengCard(true);
                                  setOpenSulutgoCard(true);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Tutup Detail
                              </Button>
                            </OverlayTrigger>
                          )}
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>
                  {/* ====================Card Body==================== */}
                  {!openSulutgo ? (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={require("assets/img/logo-sulutgo.png").default}
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseSulutgo > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseSulutgo} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <b
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Total Pencairan
                              </b>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    totalNominalPencairanSulutgo >
                                    totalNominalTargetSulutgo
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalPencairanSulutgo.toLocaleString(
                                    "id-ID"
                                  )}`}{" "}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category ">
                                <b
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                  }}
                                >
                                  Total Target
                                </b>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalTargetSulutgo.toLocaleString(
                                    "id-ID"
                                  )}`}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  ) : (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={require("assets/img/logo-sulutgo.png").default}
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseSulutgo > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseSulutgo} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                          <Row className="mt-3">
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Total Pencairan
                                  </b>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color:
                                        totalNominalPencairanSulutgo >
                                        totalNominalTargetSulutgo
                                          ? "#26DB53"
                                          : "#FF4D4F",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalPencairanSulutgo.toLocaleString(
                                        "id-ID"
                                      )}`}{" "}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <p className="card-category ">
                                    <b
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                      }}
                                    >
                                      Total Target
                                    </b>
                                  </p>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color: "#26DB53",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalTargetSulutgo.toLocaleString(
                                        "id-ID"
                                      )}`}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ) : null}
            {/* ===================CARD KALSEL======================== */}
            {openKalselCard ? (
              <Col md={!openKalsel ? "6" : "12"}>
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: 10,
                  }}
                  disabled
                  className="disabled-card"
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#67689E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title>
                      <Row>
                        <Col
                          className="p-2 align-items-center "
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            marginLeft: 10,
                          }}
                        >
                          <b style={{ color: "white" }}>bpd Kalsel</b>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          {!openKalsel ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Detail Kalsel</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "#DBD4DA",
                                  marginBottom: 15,
                                  fontSize: 16,
                                  border: 0,
                                  color: "#4D414A",
                                  fontWeight: "bold",
                                }}
                                // disabled
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(false);
                                  setOpenKalsel(true);
                                  setOpenKaltimCard(false);
                                  setOpenKaltengCard(false);
                                  setOpenSulutgoCard(false);
                                  setOpenKalselCard(true);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Detail
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>Tutup Detail</Tooltip>}
                            >
                              <Button
                                style={{
                                  maxHeight: 40,
                                  backgroundColor: "red",
                                  marginBottom: 15,
                                  border: 0,
                                  fontWeight: "bold",
                                }}
                                onClick={() => {
                                  // openCardKalteng();
                                  setOpenKaltim(false);
                                  setOpenKalsel(false);
                                  setOpenKalteng(false);
                                  setOpenSulutgo(false);
                                  setOpenKaltimCard(true);
                                  setOpenKalselCard(true);
                                  setOpenKaltengCard(true);
                                  setOpenSulutgoCard(true);
                                }}
                                className="btn-wd  "
                                type="button"
                              >
                                Tutup Detail
                              </Button>
                            </OverlayTrigger>
                          )}
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header>
                  {/* ====================Card Body==================== */}
                  {!openKalsel ? (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={require("assets/img/bank-kalsel.png").default}
                            // src="https://images.bisnis.com/posts/2023/07/26/1681021/bank-kalsel.jpg"
                            style={{ marginLeft: 20 }}
                            width="auto"
                            height="150px"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKalsel > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseKalsel} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <b
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: 20,
                                }}
                              >
                                Total Pencairan
                              </b>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKalsel > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalPencairanKalsel.toLocaleString(
                                    "id-ID"
                                  )}`}{" "}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category ">
                                <b
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                  }}
                                >
                                  Total Target
                                </b>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                }}
                              >
                                <b style={{ fontSize: 20 }}>
                                  {`Rp ${totalNominalTargetKalsel.toLocaleString(
                                    "id-ID"
                                  )}`}
                                </b>
                              </Card.Title>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  ) : (
                    <Card.Body>
                      <Row>
                        <Col
                          lg="4"
                          className="d-none d-lg-flex align-items-center justify-content-center"
                        >
                          <Image
                            src={
                              "https://images.bisnis.com/posts/2021/06/17/1406647/bank-kalsel.jpg"
                            }
                            width="200px"
                            height="auto"
                          />
                        </Col>
                        <Col lg="7" xs="12">
                          <div className="numbers d-flex justify-content-around">
                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Pencapaian
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    hasilPresentaseKalsel > 100
                                      ? "#26DB53"
                                      : "#FF4D4F",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>{hasilPresentaseKalsel} %</b>
                              </Card.Title>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <p className="card-category">
                                <h3>
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Target
                                  </b>
                                </h3>
                              </p>
                              <Card.Title
                                as="h2"
                                style={{
                                  color: "#26DB53",
                                  fontFamily: "inherit",
                                  fontWeight: "bold",
                                  fontSize: 25,
                                }}
                              >
                                <b>100%</b>
                              </Card.Title>
                            </div>
                          </div>
                          <Row className="mt-3">
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <b
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                      fontSize: 20,
                                    }}
                                  >
                                    Total Pencairan
                                  </b>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color:
                                        hasilPresentaseKalsel > 100
                                          ? "#26DB53"
                                          : "#FF4D4F",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalPencairanKalsel.toLocaleString(
                                        "id-ID"
                                      )}`}{" "}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="numbers d-flex justify-content-around">
                                <div className="d-flex flex-column align-items-center">
                                  <p className="card-category ">
                                    <b
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                      }}
                                    >
                                      Total Target
                                    </b>
                                  </p>
                                  <Card.Title
                                    as="h2"
                                    style={{
                                      color: "#26DB53",
                                      fontFamily: "inherit",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <b style={{ fontSize: 20 }}>
                                      {`Rp ${totalNominalTargetKalsel.toLocaleString(
                                        "id-ID"
                                      )}`}
                                    </b>
                                  </Card.Title>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ) : null}
          </Row>

          {/* ======================END CARD======================== */}
          {/* <Tab.Pane eventKey="detailKaltimtara">
          <DetailKaltimtara />
        </Tab.Pane> */}

          {/* ==============================DETAIL KALTIM=================== */}
          {openKaltim ? <DetailKaltimtara /> : null}
          {openKalsel ? <DetailKalsel /> : null}
          {openKalteng ? <DetailKalteng /> : null}
          {openSulutgo ? <DetailSulutgo /> : null}
        </Container>
      </Card>
    </>
  );
}

export default DashboardUtama;
