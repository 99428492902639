import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { getAllTotalAreaManagerSulutgo } from "stores";
import { getAllTotalPengajuanSulutgo } from "stores";
import ReactDatetime from "react-datetime";
import { getTotalPencairanSulutgo } from "stores/bpdSulutgo/Pengajuan/function";
import { getGrafikPencairanCabangSulutgo } from "stores";
import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { getTotalTargetUserSulutgo } from "stores/bpdSulutgo/Pengajuan/function";
import { getGrafikPencairanUserSulutgo } from "stores";
import { getAllTotalSupervisorSulutgo } from "stores";
import { getAllTotalMarketingSulutgo } from "stores";
import { getAllTotalCabangSulutgo } from "stores";
import { getExportPengajuanSulutgo } from "stores";
import "assets/img/logo-sulutgo.png";

function DetailSulutgo() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const cardTotalAreaManager = useSelector(
    (state) => state.dashboardUtamaReducerSulutgo
  );
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducerSulutgo);
  // console.log(cardTotalPencairan.data, "Dari Store");
  const cardTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducerSulutgo
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const [totalNominalPencairan, setTotalNominalPencairan] = React.useState(0);
  const [hasilPresentase, setHasilPresentase] = React.useState(0);
  const totalTarget = hasilPresentase > 100;
  const [totalNominalTarget, setTotalNominalTarget] = React.useState(0);
  const [dataGrafikCabang, setDataGrafikCabang] = React.useState([]);
  const [dataGrafikUser, setDataGrafikUser] = React.useState([]);
  const [totalSpv, setTotalSpv] = React.useState([]);
  const [totalCbg, setTotalCbg] = React.useState([]);
  const [totalAM, setTotalAM] = React.useState([]);
  const [totalMarketing, setTotalMarketing] = React.useState([]);
  const [dataAllGrafikCabang, setDataAllGrafikCabang] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [tglAwal, setTglAwal] = React.useState("");
  const [tglAkhir, setTglAkhir] = React.useState("");
  const [modalExport, setModalExport] = React.useState(false);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [modalDetail, setModalDetail] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const pencairanData = dataGrafikCabang.map((item) => item.totalPencairan);
  const targetData = dataGrafikCabang.map((item) => item.totalTarget);
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);
  const bulanData = dataGrafikUser.map((item) => item.bulan);
  const pencairanDataUser = dataGrafikUser.map(
    (item) => item.totalNominalPencairan
  );
  const targetDataUser = dataGrafikUser.map((item) => item.totalNominalTarget);
  const abjadCabang = dataGrafikCabang.map((_, index) =>
    String.fromCharCode(65 + index)
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closeModal = () => {
    setCurrentPage(1);
    setModalDetail(false);
  };

  const chartData = {
    labels: abjadCabang,
    series: [pencairanData, targetData],
  };

  const chartDataUser = {
    labels: bulanData,
    series: [pencairanDataUser, targetDataUser],
  };

  const handleExportPengajuan = () => {
    setStatus("");
    setTglAwal("");
    setTglAkhir("");
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  React.useEffect(() => {
    getAllTotalPengajuanSulutgo(dispatch);
  }, []);

  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    getTotalPencairanSulutgo(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairan(totalPencairan);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalTargetUserSulutgo(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTarget(totalTarget);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanCabangSulutgo(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 15) {
          tmp.push(val);
        }
      });
      setDataGrafikCabang(tmp);
      setDataAllGrafikCabang(grafikCabang);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanUserSulutgo(dispatch, {
      tahun: tahun,
    }).then((response) => {
      const grafikUser = response.data;
      setDataGrafikUser(grafikUser);
    });
  }, [tahun]);

  React.useEffect(() => {
    if (totalNominalPencairan !== 0 && totalNominalTarget !== 0) {
      const presentase = Math.round(
        (totalNominalPencairan / totalNominalTarget) * 100
      );
      setHasilPresentase(presentase);
    } else {
      setHasilPresentase(0);
    }
  }, [totalNominalPencairan, totalNominalTarget]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabangPencairan = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalPencairan.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalTarget.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Nasabah",
      minWidth: "120px",
      center: true,
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      selector: (row) => row.totalNasabah,
    },
  ];

  React.useEffect(() => {
    getAllTotalSupervisorSulutgo(dispatch).then((response) => {
      const dataSupervisor = response.data;
      setTotalSpv(dataSupervisor);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalAreaManagerSulutgo(dispatch).then((response) => {
      const dataAreaManager = response.data;
      setTotalAM(dataAreaManager);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalMarketingSulutgo(dispatch).then((response) => {
      const dataMarketing = response.data;
      setTotalMarketing(dataMarketing);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalCabangSulutgo(dispatch).then((response) => {
      const dataCabang = response.data;
      setTotalCbg(dataCabang);
    });
  }, []);

  const submitExport = (e) => {
    e.preventDefault();
    // console.log(tglAwal, tglAkhir, status.value, "ASLKASDASDSAD");
    // if (status === "") {
    //   Swal.fire({
    //     title: "Status Harus Diisi",
    //     icon: "warning",
    //   });
    //   return;
    // }
    getExportPengajuanSulutgo({
      tglAwal: tglAwal,
      tglAkhir: tglAkhir,
      statusPengajuan: status.value,
    });
  };

  return (
    <>
      <Modal
        size="lg"
        show={modalDetail}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairan}
                data={dataAllGrafikCabang}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStyles}
                pagination
                paginationPerPage={itemsPerPage}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <Row>
          <Col lg="6" sm="6">
            <h4
              className="title mt-2"
              style={{ fontWeight: "bold", marginBottom: 27 }}
            >
              Informasi
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Area Manager</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalAM.totalAm} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Supervisor</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalSpv.totalSpv} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Marketing</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalMarketing.totalSm} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              {auth.role === "admin" || auth.role === "super admin" ? (
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#4477CE",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>SM Sudah Login</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketing.totalLogin} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
              ) : null}

              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Cabang</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalCbg.totalCabang} Cabang
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg="6" sm="6">
            <div className="status-container">
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Status
              </h4>
            </div>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>menunggu</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.menunggu}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Revisi</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.revisi}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Diproses</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.proses}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Disetujui</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.disetujui}
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#4477CE",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Ditolak</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {cardTotalPengajuan.totalPengajuan &&
                      cardTotalPengajuan.totalPengajuan.data.ditolak}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                  Cabang
                  <hr />
                </Card.Title>
              </Card.Header>
              <Row>
                <Col md="12">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#7F669D",
                          width: 15,
                          height: 15,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Pencairan cabang</label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#79AC78",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Target Cabang</label>
                    </div>
                    <VictoryChart
                      height={280}
                      width={850}
                      style={{ parent: { marginLeft: "1rem" } }}
                      domainPadding={{ x: 50 }}
                    >
                      <VictoryGroup
                        offset={13}
                        colorScale={["#7F669D", "#79AC78"]}
                      >
                        <VictoryBar
                          data={chartData.series[0].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#7F669D",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartData.series[1].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue =
                            //   decimalDigits && decimalDigits.length > 2
                            //     ? -5
                            //     : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#79AC78",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        label="Nama Cabang"
                        tickLabelComponent={
                          <VictoryLabel style={{ fontSize: "10px" }} />
                        }
                        style={{
                          axisLabel: {
                            padding: 30,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <VictoryAxis
                        dependentAxis
                        tickCount={6}
                        label="Nominal Pencairan dalam Miliar"
                        style={{
                          axisLabel: {
                            padding: 42.5,
                            fontSize: "9px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                          tickLabels: {
                            fontSize: "10px",
                            // padding: 30,
                            // textAnchor: "start",
                          },
                        }}
                        domain={
                          chartData.series.some((data) =>
                            data.slice(0, 10).some((yValue) => yValue !== 0)
                          )
                            ? undefined
                            : { y: [0, 1] }
                        }
                      />
                    </VictoryChart>
                  </div>
                </Col>
                <Col md="12" style={{ marginTop: 30 }}>
                  <Card
                    style={{
                      border: 0,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: 10,
                      marginRight: 30,
                      marginLeft: 30,
                      // backgroundColor: "#FFF59D",
                      color: "black",
                      padding: 20,
                    }}
                  >
                    <div style={{ marginBottom: 20 }}>
                      <Row>
                        <Col>
                          <h4
                            className="title mt-2"
                            style={{
                              fontWeight: "bold",
                              marginLeft: 20,
                              textAlign: "start",
                            }}
                          >
                            Keterangan Nama Cabang
                          </h4>
                        </Col>
                        <Col style={{ textAlign: "end" }}>
                          <Button
                            className="mr-1"
                            // variant="primary"
                            style={{
                              marginBottom: 10,
                              fontSize: 16,
                              backgroundColor: "#00BFFF",
                              border: 0,
                              paddingLeft: 10,
                              paddingRight: 10,
                            }}
                            onClick={() => setModalDetail(!modalDetail)}
                          >
                            Detail Semua Cabang
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        flexWrap: "wrap",
                        display: "flex",
                      }}
                    >
                      {dataGrafikCabang.map((cabang, index) => {
                        return (
                          <div
                            style={{
                              borderWidth: 2,
                              width: 300,
                              marginTop: 10,
                            }}
                          >
                            <Row key={index}>
                              <Col sm="1">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  {String.fromCharCode(65 + index)}
                                </label>
                              </Col>
                              <Col sm="o">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",

                                    color: "black",
                                  }}
                                >
                                  :
                                </label>
                              </Col>
                              <Col sm="9">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",

                                    color: "black",
                                  }}
                                >
                                  {cabang.nama}
                                </label>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}

                      {/* <Row>
                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index < 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                 
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                  
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>

                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index >= 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                        
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>
                      </Row> */}
                    </div>
                    {/* <div
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <Button
                        className="btn-wd mr-1"
                        variant="primary"
                        style={{ marginTop: 15, marginBottom: 10 }}
                        onClick={() => setModalDetail(!modalDetail)}
                      >
                        Tampilkan Detail Semua Cabang
                      </Button>
                    </div> */}
                  </Card>
                </Col>
              </Row>

              {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                  <span> {auth.nama} </span>
                  <hr />
                </Card.Title>
                <Col md={"3"}>
                  <label style={{ color: "black", fontSize: 14 }}>
                    Pilih Tahun
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    defaultValue={{ label: yearNow, value: yearNow }}
                    onChange={(value) => {
                      setTahun(value.value);
                    }}
                    options={listYear}
                    // placeholder="Pilih Tahun"
                  />
                  {/* <Form.Control
                    onChange={(e) => {
                      setTahun(e.target.value);
                    }}
                    placeholder="Input Tahun"
                    type="month"
                  ></Form.Control> */}
                  {/* <p style={{ color: "gray", marginTop: 10 }}>
                    Tahun dan bulan saat ini :{" "}
                    {isDateValid
                      ? moment(tahun).format("MMMM YYYY")
                      : "Tidak ada data"}
                  </p> */}
                </Col>
              </Card.Header>
              <Row>
                <Col md="12">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#FFD966",
                          width: 15,
                          height: 15,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Pencairan User</label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#046582",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Target User</label>
                    </div>
                    <VictoryChart
                      height={250}
                      width={850}
                      style={{ parent: { marginLeft: "1rem" } }}
                      domainPadding={{ x: 50 }}
                    >
                      <VictoryGroup
                        offset={12.5}
                        colorScale={["#FFD966", "#046582"]}
                      >
                        <VictoryBar
                          data={chartDataUser.series[0].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#FFD966",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartDataUser.series[1].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              dy={-2}
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#046582",
                                padding: 5,
                              }}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        tickLabelComponent={
                          <VictoryLabel style={{ fontSize: "10px" }} />
                        }
                        label="Bulan"
                        style={{
                          axisLabel: {
                            padding: 30,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <VictoryAxis
                        dependentAxis
                        label="Nominal Pencairan dalam Miliar"
                        style={{
                          axisLabel: {
                            padding: 42.5,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                          tickLabels: {
                            fontSize: "10px",
                          },
                        }}
                        domain={
                          chartDataUser.series.some((data) =>
                            data.some((yValue) => yValue !== 0)
                          )
                            ? undefined
                            : { y: [0, 1] }
                        }
                      />
                    </VictoryChart>
                  </div>
                </Col>
              </Row>

              {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DetailSulutgo;
