const initialState = {
  listCabang: [],
  listAllAnggotaCabang: [],
  statusInputTargetCabang: true,
  listTargetCabang: [],
};

const cabangReducerSulutgo = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLCABANG":
      return {
        ...state,
        listCabang: data,
      };
    case "SET_ALLANGGOTACABANG":
      return {
        ...state,
        listAllAnggotaCabang: data,
      };
    case "SET_STATUS_INPUT_TARGET_CABANG":
      return {
        ...state,
        statusInputTargetCabang: data,
      };
    case "SET_ALL_TARGET_CABANG":
      return {
        ...state,
        listTargetCabang: data,
      };
    default:
      return state;
  }
};
export default cabangReducerSulutgo;
