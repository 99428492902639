import { baseAxiosKaltimtara } from "../../index";

export async function getAllUserKaltimtara(dispatch, data, history) {
  try {
    const response = await baseAxiosKaltimtara("webUser/getAllUser", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLUSER", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahSuperAdminKaltimtara(data) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webUser/tambahSuperAdmin",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response.status;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function tambahUserKaltimtara(data) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webUser/tambahUser",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function editStatusUserKaltimtara(data) {
  try {
    const response = await baseAxiosKaltimtara.patch(
      "webUser/ubahStatusUser",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    throw err.response.data;
  }
}

export async function addProfileKaltimtara(data) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webLogin/addProfile",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

// export async function addProfile(data) {
//   try {
//     const response = await baseAxiosKaltimtara.post("webLogin/addProfile", data, {
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     });
//     return response;
//   } catch (err) {
//     console.log("error upload", err);
//     return err.response;
//   }
// }

export async function getAllProfileKaltimtara(dispatch, data, history) {
  try {
    const response = await Kaltimtara.post("webLogin/getProfile", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // dispatch({ type: "SET_ALL_PROFILE", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

// export async function updateFotoProfile(data) {
//   try {
//     const response = await baseAxiosKaltimtara.patch("webLogin/updateFotoProfile", data, {
//       headers: { token: localStorage.getItem("token") },
//     });
//     console.log(response);
//     return response.data;
//   } catch (err) {
//     throw err.response.data;
//   }
// }

export async function updateFotoProfileKaltimtara(data) {
  try {
    const response = await baseAxios.patch("webLogin/updateFotoProfile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
