const initialState = {
  totalSupervisor: [],
  totalMarketing: [],
  totalAreaManager: [],
  totalCabang: [],
  totalPengajuan: null,

  detailKaltimtara: true,
};

const dashboardUtamaReducerKalteng = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    //Detail Kaltimtara
    case "SET_DETAIL_KALTIMTARA":
      return {
        ...state,
        detailKaltimtara: data,
      };

    case "SET_ALL_TOTAL_SUPERVISOR":
      return {
        ...state,
        totalSupervisor: data,
      };
    case "SET_ALL_TOTAL_AREAMANAGER":
      return {
        ...state,
        totalAreaManager: data,
      };
    case "SET_ALL_TOTAL_MARKETING":
      return {
        ...state,
        totalMarketing: data,
      };
    case "SET_ALL_TOTAL_CABANG":
      return {
        ...state,
        totalCabang: data,
      };
    case "SET_ALL_TOTAL_PENGAJUAN":
      return {
        ...state,
        totalPengajuan: data,
      };
    default:
      return state;
  }
};
export default dashboardUtamaReducerKalteng;
