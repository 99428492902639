import { baseAxioKalsel } from "../../index";

export async function getAllPengajuanKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALL_PENGAJUAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getPengajuanByIdPengajuanKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.get(
      `webPengajuan/getPengajuanByIdPengajuan?idPengajuan=${data.idPengajuan}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    // dispatch({ type: "SET_STEP_PENGAJUAN", data: false });
    dispatch({ type: "SET_ALL_PENGAJUAN_BY_ID", data: response.data.data[0] });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllPencairanKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.get(
      `webPengajuan/getPengajuanByLogin?status=${data.status}&page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalPencairanKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.post(
      "webDashboard/getTotalPencairan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTotalTargetUserKalsel(dispatch, data, history) {
  try {
    const response = await baseAxioKalsel.post(
      "webDashboard/getTargetUserLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({
    //   type: "SET_ALL_TOTAL_PENCAIRAN",
    //   data: response.data.totalNominal,
    // });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function revisiPengajuanKalsel(data) {
  try {
    const response = await baseAxioKalsel.post(
      "webPengajuan/revisiPengajuanDariAtasan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanBPDKalsel(data) {
  try {
    const response = await baseAxioKalsel.post("webApiBpd/pengajuan", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function pengajuanUlangDokumenKalsel(data) {
  try {
    const response = await baseAxioKalsel.post(
      "webApiBpd/pengajuanUlangDokumen",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cekStatusPengajuanKalsel(data) {
  try {
    const response = await baseAxioKalsel.post(
      "webApiBpd/cekStatusManual",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateStatusManualKalsel(data) {
  try {
    const response = await baseAxioKalsel.post(
      "webApiBpd/updateStatusManual",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}
