import { baseAxiosKaltimtara } from "../../index";

export async function getAllTotalSupervisorKaltimtara(dispatch) {
  try {
    const response = await baseAxiosKaltimtara.get(
      "webDashboard/getTotalSupervisor",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_SUPERVISOR", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalAreaManagerKaltimtara(dispatch) {
  try {
    const response = await baseAxiosKaltimtara.get(
      "webDashboard/getTotalAreaManager",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_AREAMANAGER", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalMarketingKaltimtara(dispatch) {
  try {
    const response = await baseAxiosKaltimtara.get(
      "webDashboard/getTotalMarketing",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_MARKETING", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalCabangKaltimtara(dispatch) {
  try {
    const response = await baseAxiosKaltimtara.get(
      "webDashboard/getTotalCabang",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_CABANG", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalPengajuanKaltimtara(dispatch, data, history) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webPengajuan/getAllTotalPengajuan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_PENGAJUAN", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanCabangKaltimtara(
  dispatch,
  data,
  history
) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webDashboard/getGrafikPencairanCabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanUserKaltimtara(
  dispatch,
  data,
  history
) {
  try {
    const response = await baseAxiosKaltimtara.post(
      "webDashboard/getGrafikPencairanPerBulanByLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}
