import { baseAxiosSulutgo } from "../../index";

export async function getAllTotalSupervisorSulutgo(dispatch) {
  try {
    const response = await baseAxiosSulutgo.get(
      "webDashboard/getTotalSupervisor",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_SUPERVISOR", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalAreaManagerSulutgo(dispatch) {
  try {
    const response = await baseAxiosSulutgo.get(
      "webDashboard/getTotalAreaManager",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_AREAMANAGER", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalMarketingSulutgo(dispatch) {
  try {
    const response = await baseAxiosSulutgo.get(
      "webDashboard/getTotalMarketing",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_MARKETING", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalCabangSulutgo(dispatch) {
  try {
    const response = await baseAxiosSulutgo.get("webDashboard/getTotalCabang", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_CABANG", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalPengajuanSulutgo(dispatch) {
  try {
    const response = await baseAxiosSulutgo.get(
      "webPengajuan/getAllTotalPengajuan",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_PENGAJUAN", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanCabangSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.post(
      "webDashboard/getGrafikPencairanCabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanUserSulutgo(dispatch, data, history) {
  try {
    const response = await baseAxiosSulutgo.post(
      "webDashboard/getGrafikPencairanPerBulanByLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    // console.log(response, "PPPPPPPP");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getExportPengajuanSulutgo(data) {
  // console.log(data, "PPPPP");
  baseAxiosSulutgo
    .get(
      `webPengajuan/exportPengajuan?tglAwal=${data.tglAwal}&tglAkhir=${
        data.tglAkhir
      }&statusPengajuan=${
        data.statusPengajuan === undefined || data.statusPengajuan === ""
          ? ""
          : data.statusPengajuan
      }`,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    )
    .then((respon) => {
      const url = window.URL.createObjectURL(new Blob([respon.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exportPengajuan.xlsx`);
      document.body.appendChild(link);
      link.click();
      Swal.close();
      console.log(respon, "RESPON");
    })
    .catch((err) => {
      console.log(err, "ERR");
    });

  // baseAxiosSulutgo
  //   .get(
  //     `/webPengajuan/exportPengajuan?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&statusPengajuan=${data.status}`,
  //     data,
  //     {
  //       // headers: {
  //       //   token: localStorage.getItem("token"),
  //       // },
  //       responseType: "blob",
  //     }.then((respon) => {
  //       console.log(respon, "DARI AXIOSSSSSS");
  //       // console.log(respon.data, "?????????????");
  //       // const url = window.URL.createObjectURL(new Blob([respon.data]));
  //       // const link = document.createElement("a");
  //       // link.href = url;
  //       // link.setAttribute("download", `exportPengajuan.xlsx`);
  //       // document.body.appendChild(link);
  //       // link.click();
  //       // Swal.close();
  //     })
  //   )
  //   .catch((err) => {
  //     console.log(err);
  //   });
}

// export async function getExportPengajuan(data) {
//   console.log(data, "LLLLL");
//   try {
//     const response = await axios.get(
//       `webPengajuan/exportPengajuan?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}&statusPengajuan=${data.statusPengajuan}`,
//       {
//         responseType: "blob",
//         headers: {
//           token: localStorage.getItem("token"),
//         },
//       }
//     );
//     console.log(response, "BERHASIL DOWNLOAD");
//     return response;
//   } catch (err) {
//     throw err;
//   }
// }
