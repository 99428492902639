const initialState = {
  listMarketing: [],
  statusInputTargetMarketing: true,
  listTargetMarketing: [],
};

const marketingReducerKalsel = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLMARKETING":
      return {
        ...state,
        listMarketing: data,
      };
    case "SET_STATUS_INPUT_TARGET_MARKETING":
      return {
        ...state,
        statusInputTargetMarketing: data,
      };
    case "SET_ALL_TARGET_MARKETING":
      return {
        ...state,
        listTargetMarketing: data,
      };
    default:
      return state;
  }
};
export default marketingReducerKalsel;
