const initialState = {
  listUser: [],
};

const userReducerKalsel = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLUSER":
      return {
        ...state,
        listUser: data,
      };
    default:
      return state;
  }
};
export default userReducerKalsel;
