import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import ReactDatetime from "react-datetime";

import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";

import { getTotalTargetUserKalteng } from "stores/bpdKalteng/Pengajuan/function";
import { getTotalPencairanKalteng } from "stores/bpdKalteng/Pengajuan/function";

import { getGrafikPencairanUserKalteng } from "stores";
import { getAllTotalSupervisorKalteng } from "stores";
import { getAllTotalMarketingKalteng } from "stores";
import { getAllTotalCabangKalteng } from "stores";
import { getExportPengajuanKalteng } from "stores";
import { getAllTotalAreaManagerKalteng } from "stores";
import { getAllTotalPengajuanKalteng } from "stores";
import { getGrafikPencairanCabangKalteng } from "stores";

function DetailKalteng() {
  const dispatch = useDispatch();
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);

  const auth = useSelector((state) => state.authReducer);
  const cardTotalAreaManagerKalteng = useSelector(
    (state) => state.dashboardUtamaReducer
  );
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducer);
  // console.log(cardTotalPencairan.data, "Dari Store");
  const [dataGrafikUserKalteng, setDataGrafikUserKalteng] = React.useState([]);
  const targetDataUserKalteng = dataGrafikUserKalteng.map(
    (item) => item.totalNominalTarget
  );
  const cardTotalPengajuanKalteng = useSelector(
    (state) => state.dashboardUtamaReducerKalteng
  );
  const [currentPageKalteng, setCurrentPageKalteng] = React.useState(1);
  const itemsPerPageKalteng = 10;
  const [dataGrafikCabangKalteng, setDataGrafikCabangKalteng] = React.useState(
    []
  );
  const pencairanDataUserKalteng = dataGrafikUserKalteng.map(
    (item) => item.totalNominalPencairan
  );
  const bulanDataKalteng = dataGrafikUserKalteng.map((item) => item.bulan);

  const [totalSpvKalteng, setTotalSpvKalteng] = React.useState([]);
  const [totalCbgKalteng, setTotalCbgKalteng] = React.useState([]);
  const [totalAMKalteng, setTotalAMKalteng] = React.useState([]);
  const [totalMarketingKalteng, setTotalMarketingKalteng] = React.useState([]);
  const [dataAllGrafikCabangKalteng, setDataAllGrafikCabangKalteng] =
    React.useState([]);
  const [statusKalteng, setStatusKalteng] = React.useState("");

  const [modalExportKalteng, setModalExportKalteng] = React.useState(false);

  const [modalDetailKalteng, setModalDetailKalteng] = React.useState(false);
  const pencairanDataKalteng = dataGrafikCabangKalteng.map(
    (item) => item.totalPencairan
  );
  const targetDataKalteng = dataGrafikCabangKalteng.map(
    (item) => item.totalTarget
  );
  const [tahunKalteng, setTahunKalteng] = React.useState(
    new Date().getFullYear()
  );

  const abjadCabangKalteng = dataGrafikCabangKalteng.map((_, index) =>
    String.fromCharCode(65 + index)
  );

  const [cardDetailKalteng, setCardDetailKalteng] = React.useState(false);
  const [totalNominalPencairanKalteng, setTotalNominalPencairanKalteng] =
    React.useState(0);
  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  const handlePageChangeKalteng = (page) => {
    setCurrentPageKalteng(page);
  };

  const closeModalKalteng = () => {
    setCurrentPageKalteng(1);
    setModalDetailKalteng(false);
  };

  const chartDataKalteng = {
    labels: abjadCabangKalteng,
    series: [pencairanDataKalteng, targetDataKalteng],
  };

  const handleExportPengajuanKalteng = () => {
    setStatus("");
    setTglAwal("");
    setTglAkhir("");
  };

  React.useEffect(() => {
    getAllTotalPengajuanKalteng(dispatch);
  }, []);

  React.useEffect(() => {
    getGrafikPencairanCabangKalteng(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 15) {
          tmp.push(val);
        }
      });
      setDataGrafikCabangKalteng(tmp);
      setDataAllGrafikCabangKalteng(grafikCabang);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanUserKalteng(dispatch, {
      tahun: tahun,
    }).then((response) => {
      const grafikUser = response.data;
      setDataGrafikUserKalteng(grafikUser);
    });
  }, [tahun]);

  const customStylesKalteng = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const chartDataUserKalteng = {
    labels: bulanDataKalteng,
    series: [pencairanDataUserKalteng, targetDataUserKalteng],
  };

  const colCabangPencairanKalteng = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) =>
        (currentPageKalteng - 1) * itemsPerPageKalteng + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalPencairan.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalTarget.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Nasabah",
      minWidth: "120px",
      center: true,
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      selector: (row) => row.totalNasabah,
    },
  ];

  React.useEffect(() => {
    getAllTotalSupervisorKalteng(dispatch).then((response) => {
      const dataSupervisor = response.data;
      setTotalSpvKalteng(dataSupervisor);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalAreaManagerKalteng(dispatch).then((response) => {
      const dataAreaManager = response.data;
      setTotalAMKalteng(dataAreaManager);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalMarketingKalteng(dispatch).then((response) => {
      const dataMarketing = response.data;
      setTotalMarketingKalteng(dataMarketing);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalCabangKalteng(dispatch).then((response) => {
      const dataCabang = response.data;
      setTotalCbgKalteng(dataCabang);
    });
  }, []);

  const closeModal = () => {
    setCurrentPageKalteng(1);
    setModalDetailKalteng(false);
  };
  // getExportPengajuanKalteng({
  //   tglAwal: tglAwal,
  //   tglAkhir: tglAkhir,
  //   statusPengajuan: status.value,
  // });

  React.useEffect(() => {
    getTotalPencairanKalteng(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairanKalteng(totalPencairan);
    });
  }, [dariTgl, smpTgl]);

  return (
    <>
      {/* ==================Tampilan Kalteng ================*/}
      <Modal
        size="lg"
        show={modalDetailKalteng}
        onHide={closeModal}
        // onHide={() => setModalDetailKalteng(!modalDetailKalteng)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairanKalteng}
                data={dataAllGrafikCabangKalteng}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStylesKalteng}
                pagination
                paginationPerPage={itemsPerPageKalteng}
                onChangePage={handlePageChangeKalteng}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>
      {/* <Row>
        <Col className="mt-2">
          <h3 style={{ fontWeight: "bold" }}>DETAIL KALTENG</h3>
        </Col> */}

      <Row>
        <Col lg="6" sm="6">
          <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
            Informasi
          </h4>
          <Row>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Area Manager</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {totalAMKalteng.totalAm} Karyawan
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Supervisor</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {totalSpvKalteng.totalSpv} Karyawan
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Marketing</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {totalMarketingKalteng.totalSm} Karyawan
                </Card.Body>
              </Card>
            </Col>
            {auth.role === "admin" || auth.role === "super admin" ? (
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#205295",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>SM Sudah Login</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalMarketingKalteng.totalLogin} Karyawan
                  </Card.Body>
                </Card>
              </Col>
            ) : null}

            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Cabang</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {totalCbgKalteng.totalCabang} Cabang
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col lg="6" sm="6">
          <div className="status-container">
            <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
              Status
              {/* {auth.role === "super admin" || auth.role === "admin" ? (
                  <Button
                    className="btn-wd mr-1"
                    onClick={() => {
                      setModalExport(true);
                    }}
                    // variant="success"
                    style={{
                      marginLeft: 20,
                      backgroundColor: "#79AC78",
                      borderColor: "#79AC78",
                    }}
                  >
                    <i
                      className="fas fa-file-excel fa-lg"
                      style={{ marginRight: 10 }}
                    ></i>
                    Export Pengajuan
                  </Button>
                ) : null} */}
            </h4>
          </div>
          <Row>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>menunggu</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {cardTotalPengajuanKalteng.totalPengajuan &&
                    cardTotalPengajuanKalteng.totalPengajuan.data.menunggu}
                </Card.Body>
              </Card>
            </Col>

            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Revisi</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {cardTotalPengajuanKalteng.totalPengajuan &&
                    cardTotalPengajuanKalteng.totalPengajuan.data.revisi}
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Diproses</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {cardTotalPengajuanKalteng.totalPengajuan &&
                    cardTotalPengajuanKalteng.totalPengajuan.data.proses}
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Disetujui</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {cardTotalPengajuanKalteng.totalPengajuan &&
                    cardTotalPengajuanKalteng.totalPengajuan.data.disetujui}
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" sm="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: 10,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#205295",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  <Card.Title
                    as="h6"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    <b style={{ color: "white" }}>Ditolak</b>
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {cardTotalPengajuanKalteng.totalPengajuan &&
                    cardTotalPengajuanKalteng.totalPengajuan.data.ditolak}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                Cabang
                <hr />
              </Card.Title>
            </Card.Header>
            <Row>
              <Col md="12">
                <div>
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#FFD966",
                        width: 15,
                        height: 15,
                        marginRight: 5,
                      }}
                    ></div>
                    <label style={{ fontSize: 14 }}>Pencairan cabang</label>
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#C9F4AA",
                        width: 15,
                        height: 15,
                        marginLeft: 20,
                        marginRight: 5,
                      }}
                    ></div>
                    <label style={{ fontSize: 14 }}>Target Cabang</label>
                  </div>
                  <VictoryChart
                    height={280}
                    width={850}
                    style={{ parent: { marginLeft: "1rem" } }}
                    domainPadding={{ x: 50 }}
                  >
                    <VictoryGroup
                      offset={13}
                      colorScale={["#FFD966", "#C9F4AA"]}
                    >
                      <VictoryBar
                        data={chartDataKalteng.series[0].map(
                          (yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataKalteng.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          }
                        )}
                        barWidth={10}
                        labelComponent={
                          <VictoryTooltip
                            pointerLength={0}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#FFD966",
                              padding: 5,
                            }}
                            dy={-2}
                            style={{
                              fontSize: 10,
                              fill: "white",
                            }}
                          />
                        }
                      />

                      <VictoryBar
                        data={chartDataKalteng.series[1].map(
                          (yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue =
                            //   decimalDigits && decimalDigits.length > 2
                            //     ? -5
                            //     : -10;

                            return {
                              x: chartDataKalteng.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          }
                        )}
                        barWidth={10}
                        labelComponent={
                          <VictoryTooltip
                            pointerLength={0}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#C9F4AA",
                              padding: 5,
                            }}
                            dy={-2}
                            style={{
                              fontSize: 10,
                              fill: "white",
                            }}
                          />
                        }
                      />
                    </VictoryGroup>
                    <VictoryAxis
                      label="Nama Cabang"
                      tickLabelComponent={
                        <VictoryLabel style={{ fontSize: "10px" }} />
                      }
                      style={{
                        axisLabel: {
                          padding: 30,
                          fontSize: "8px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        },
                      }}
                    />
                    <VictoryAxis
                      dependentAxis
                      tickCount={6}
                      label="Nominal Pencairan dalam Miliar"
                      style={{
                        axisLabel: {
                          padding: 42.5,
                          fontSize: "9px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        },
                        tickLabels: {
                          fontSize: "10px",
                        },
                      }}
                      domain={
                        chartDataKalteng.series.some((data) =>
                          data.slice(0, 10).some((yValue) => yValue !== 0)
                        )
                          ? undefined
                          : { y: [0, 1] }
                      }
                    />
                  </VictoryChart>
                </div>
              </Col>
              <Col md="12" style={{ marginTop: 30 }}>
                <Card
                  style={{
                    border: 0,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: 10,
                    marginRight: 30,
                    marginLeft: 30,
                    // backgroundColor: "#FFF59D",
                    color: "black",
                    padding: 20,
                  }}
                >
                  <div style={{ marginBottom: 20 }}>
                    <Row>
                      <Col>
                        <h4
                          className="title mt-2"
                          style={{
                            fontWeight: "bold",
                            marginLeft: 20,
                            textAlign: "start",
                          }}
                        >
                          Keterangan Nama Cabang
                        </h4>
                      </Col>
                      <Col style={{ textAlign: "end" }}>
                        <Button
                          className="mr-1"
                          // variant="primary"
                          style={{
                            marginBottom: 10,
                            fontSize: 16,
                            backgroundColor: "#00BFFF",
                            border: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                          onClick={() =>
                            setModalDetailKalteng(!modalDetailKalteng)
                          }
                        >
                          Detail Semua Cabang
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div
                    style={{
                      marginLeft: 20,
                      flexWrap: "wrap",
                      display: "flex",
                    }}
                  >
                    {dataGrafikCabangKalteng.map((cabang, index) => {
                      return (
                        <div
                          style={{
                            borderWidth: 2,
                            width: 300,
                            marginTop: 10,
                          }}
                        >
                          <Row key={index}>
                            <Col sm="1">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                {String.fromCharCode(65 + index)}
                              </label>
                            </Col>
                            <Col sm="o">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",

                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="9">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",

                                  color: "black",
                                }}
                              >
                                {cabang.nama}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </Col>
            </Row>

            {/* <ChartistGraph
                  data={chartDataKalteng}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
            {/* </Card.Body> */}
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                <span> {auth.nama} </span>
                <hr />
              </Card.Title>
              <Col md={"3"}>
                <label style={{ color: "black", fontSize: 14 }}>
                  Pilih Tahun
                </label>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  defaultValue={{ label: yearNow, value: yearNow }}
                  onChange={(value) => {
                    setTahun(value.value);
                  }}
                  options={listYear}
                  // placeholder="Pilih Tahun"
                />
              </Col>
            </Card.Header>
            <Row>
              <Col md="12">
                <div>
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#FFB6C1",
                        width: 15,
                        height: 15,
                        marginRight: 5,
                      }}
                    ></div>
                    <label style={{ fontSize: 14 }}>Pencairan User</label>
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#AF7AB3",
                        width: 15,
                        height: 15,
                        marginLeft: 20,
                        marginRight: 5,
                      }}
                    ></div>
                    <label style={{ fontSize: 14 }}>Target User</label>
                  </div>
                  <VictoryChart
                    height={250}
                    width={850}
                    style={{ parent: { marginLeft: "1rem" } }}
                    domainPadding={{ x: 50 }}
                  >
                    <VictoryGroup
                      offset={12.5}
                      colorScale={["#FFB6C1", "#AF7AB3"]}
                    >
                      <VictoryBar
                        data={chartDataUserKalteng.series[0].map(
                          (yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUserKalteng.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          }
                        )}
                        barWidth={10}
                        labelComponent={
                          <VictoryTooltip
                            pointerLength={0}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#FFB6C1",
                              padding: 5,
                            }}
                            dy={-2}
                            style={{
                              fontSize: 10,
                              fill: "white",
                            }}
                          />
                        }
                      />

                      <VictoryBar
                        data={chartDataUserKalteng.series[1].map(
                          (yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUserKalteng.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          }
                        )}
                        barWidth={10}
                        labelComponent={
                          <VictoryTooltip
                            dy={-2}
                            pointerLength={0}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#CBC3E3",
                              padding: 5,
                            }}
                            style={{
                              fontSize: 10,
                              fill: "white",
                            }}
                          />
                        }
                      />
                    </VictoryGroup>
                    <VictoryAxis
                      tickLabelComponent={
                        <VictoryLabel style={{ fontSize: "10px" }} />
                      }
                      label="Bulan"
                      style={{
                        axisLabel: {
                          padding: 30,
                          fontSize: "8px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        },
                      }}
                    />
                    <VictoryAxis
                      dependentAxis
                      label="Nominal Pencairan dalam Miliar"
                      style={{
                        axisLabel: {
                          padding: 42.5,
                          fontSize: "8px",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        },
                        tickLabels: {
                          fontSize: "10px",
                        },
                      }}
                      domain={
                        chartDataUserKalteng.series.some((data) =>
                          data.some((yValue) => yValue !== 0)
                        )
                          ? undefined
                          : { y: [0, 1] }
                      }
                    />
                  </VictoryChart>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* </Row> */}
    </>
  );
}

export default DetailKalteng;
