import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import moment from "moment";
import "moment/locale/id";
import { addProfileSulutgo } from "stores";
import { getAllProfileSulutgo } from "stores";
import { updateFotoProfileSulutgo } from "stores";
import Compressor from "compressorjs";
// import { ImageCompressor } from "image-compressor";
// import { compress } from "image-compressor";

function UserProfile() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  //   console.log(auth, "DDDDDD");
  const [email, setEmail] = React.useState("");
  const [noHp, setNoHp] = React.useState("");
  const [alamat, setAlamat] = React.useState("");
  const [tglLahir, setTglLahir] = React.useState("");
  const [kodeUser, setKodeUser] = React.useState("");
  const [foto, setFoto] = React.useState("");
  const [getEmail, setGetEmail] = React.useState("");
  const [getNoHp, setGetNoHp] = React.useState("");
  const [getAlamat, setGetAlamat] = React.useState("");
  const [getTglLahir, setGetTglLahir] = React.useState("");
  const [getKodeUser, setGetKodeUser] = React.useState("");
  const [getFoto, setGetFoto] = React.useState("");
  const [modalEditFoto, setModalEditFoto] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [sizeFile, setSizeFile] = React.useState(0);

  const handleMouseEnter = () => {
    setIsHovered(true);
    // setBlurRadius("2px"); // Mengaktifkan blur ketika kursor masuk
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // setBlurRadius("0"); // Menonaktifkan blur ketika kursor meninggalkan
  };

  const submitProfile = (e) => {
    const data = {
      email: email,
      noHp: noHp,
      alamat: alamat,
      tglLahir: tglLahir,
      kodeUser: kodeUser,
    };
    addProfile(data)
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          getAllProfileData();
        } else if (response.data.status === 400) {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: response.data.message,
          //   });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.error("Terjadi kesalahan saat mengirim data profil:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan dalam mengirim data profil.",
        });
      });
  };

  const submitUpdateFoto = (e) => {
    if (sizeFile > 1) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Ukuran File Terlalu Besar",
        showCancelButton: true,
      });
    } else {
      const formData = new FormData();
      formData.append("fotoProfile", foto);
      formData.append("kodeUser", kodeUser);
      updateFotoProfile(formData)
        .then((response) => {
          console.log(response, "LLLLLLL");
          if (response.status === 200) {
            window.location.reload();
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 1000,
            });
            setModalEditFoto(false);
            getAllProfileData();
          } else if (response.status === 400) {
            console.log(response.status, "XXXXXXX");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.message,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.message,
            });
          }
        })
        .catch((error) => {
          console.error("Terjadi kesalahan saat mengirim data profil:", error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terjadi kesalahan dalam mengirim data profil.",
          });
        });
    }
  };

  // const submitUpdateFoto = async (e) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("fotoProfile", foto);
  //     formData.append("kodeUser", kodeUser);
  //     const response = await updateFotoProfile(formData);
  //     if (response.status === 200) {
  //       Swal.fire({
  //         position: "center",
  //         icon: "success",
  //         title: response.message,
  //         showConfirmButton: false,
  //         timer: 1000,
  //       });
  //       setModalEditFoto(false);
  //       getAllProfileData();
  //       window.location.reload();
  //     } else if (response.status === 400) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: response.message,
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: response.message,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Terjadi kesalahan saat mengirim data profil:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Terjadi kesalahan dalam mengirim data profil.",
  //     });
  //   }
  // };

  const getAllProfileData = async () => {
    setKodeUser(auth.kodeUser);
    try {
      const response = await getAllProfile(dispatch, {
        kodeUser: auth.kodeUser,
      });
      if (response.data.status === 200) {
        const userData = response.data.data;
        setGetEmail(userData.email);
        setEmail(userData.email);
        setGetNoHp(userData.noHp);
        setNoHp(userData.noHp);
        setGetAlamat(userData.alamat);
        setAlamat(userData.alamat);
        setGetTglLahir(userData.tglLahir);
        setTglLahir(userData.tglLahir);
        setGetKodeUser(userData.kodeUser);
        setGetFoto(userData.fotoProfile);
        setFoto(userData.fotoProfile);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data profil:", error);
    }
  };

  React.useEffect(() => {
    getAllProfileData();
  }, [auth.kodeUser]);
  return (
    <>
      <Modal
        size="lg"
        show={modalEditFoto}
        onHide={() => {
          // handleModalClose();
          setModalEditFoto(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h5"
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontFamily: "sans-serif",
                }}
              >
                Edit Foto Profile
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col md="12">
                    <Form.Control
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        setFoto(e.target.files[0]);
                        setSizeFile(e.target.files[0].size / 1000000);
                      }}
                    ></Form.Control>
                    <label
                      style={{
                        textTransform: "none",
                        color: "red",
                        fontSize: 12,
                      }}
                    >
                      *Ukuran file harus kurang dari 1 MB
                    </label>
                    <p
                      style={{
                        // marginTop: 5,
                        marginLeft: 5,
                        color: "blue",
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      {sizeFile.toFixed(3)} MB{" "}
                    </p>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                //
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={submitUpdateFoto}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Row>
            <Col md="8">
              <Card>
                {/* <Card.Header> */}
                {/* <Card.Header> */}
                <Card.Title
                  as="h4"
                  style={{ fontWeight: "bold", marginTop: 10, marginLeft: 15 }}
                >
                  Edit Profile
                  <hr></hr>
                </Card.Title>
                {/* </Card.Header> */}
                {/* </Card.Header> */}
                <Card.Body>
                  <Form action="" className="form" method="">
                    <Row>
                      <Col className="pl-3" md="4">
                        <Form.Group>
                          <label style={{ fontWeight: "bold", color: "black" }}>
                            Nopeg
                          </label>
                          <Form.Control
                            defaultValue={auth.nopeg}
                            disabled
                            placeholder="Company"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pr-3" md="8">
                        <Form.Group>
                          <label style={{ fontWeight: "bold", color: "black" }}>
                            Nama
                          </label>
                          <Form.Control
                            defaultValue={auth.nama}
                            disabled
                            placeholder="Username"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col className="pl-3" md="12">
                        <Form.Group>
                          <label style={{ fontWeight: "bold", color: "black" }}>
                            Email
                          </label>
                          <Form.Control
                            defaultValue={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            placeholder="Email"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label style={{ fontWeight: "bold", color: "black" }}>
                          Alamat
                        </label>
                        <Form.Control
                          defaultValue={alamat}
                          onChange={(e) => {
                            setAlamat(e.target.value);
                          }}
                          placeholder="Alamat"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label style={{ fontWeight: "bold", color: "black" }}>
                          No. HP
                        </label>
                        <Form.Control
                          defaultValue={noHp}
                          onChange={(e) => {
                            setNoHp(e.target.value);
                          }}
                          placeholder="Nomor Handphone"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="4">
                      <Form.Group>
                        <label style={{ fontWeight: "bold", color: "black" }}>
                          Tgl Lahir
                        </label>
                        <Form.Control
                          defaultValue={tglLahir}
                          onChange={(e) => {
                            setTglLahir(e.target.value);
                          }}
                          placeholder="Tanggal Lahir"
                          type="date"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label style={{ fontWeight: "bold", color: "black" }}>
                          Kode User
                        </label>
                        <Form.Control
                          defaultValue={kodeUser}
                          onChange={(e) => {
                            setKodeUser(e.target.value);
                          }}
                          disabled
                          placeholder="Kode User"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    // variant="info"
                    style={{ backgroundColor: "#00BFFF", border: 0 }}
                    onClick={submitProfile}
                  >
                    Update Profile
                  </Button>
                  <div className="clearfix"></div>
                </Card.Body>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <Card.Header className="no-padding">
                  <div className="card-image">
                    <img
                      alt="..."
                      src={
                        require("assets/img/full-screen-image-3.jpg").default
                      }
                    ></img>
                  </div>
                </Card.Header>
                <Card.Body className="form-card-body">
                  <div className="author">
                    <a>
                      <div
                        className="image-container"
                        onMouseEnter={() => {
                          setIsHovered(true);
                        }}
                        onMouseLeave={() => {
                          setIsHovered(false);
                        }}
                      >
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Edit Image</Tooltip>}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {isHovered && (
                              <div
                                // className="author"
                                onClick={() => {
                                  setModalEditFoto(true);
                                }}
                                style={{
                                  backgroundColor: "rgba(0,0,0,0.3)",
                                  position: "absolute",
                                  zIndex: 5,
                                  height: "100%",
                                  width: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                  flex: 1,
                                  display: "flex",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  //   top: "50%",
                                  //   left: "50%",
                                  //   transform: "translate(-50%, -50%)",
                                }}
                              >
                                <i
                                  className="fas fa-camera fa-2x"
                                  style={{ color: "#FFFFFF", fontSize: 20 }}
                                ></i>{" "}
                              </div>
                            )}
                            <img
                              alt="Foto Profil"
                              //   className="avatar border"
                              src={
                                getFoto
                                  ? `${getFoto}?${new Date().getTime()}`
                                  : require("assets/img/defaultUser.png")
                                      .default
                              }
                              style={{
                                margin: 0,
                                backgroundColor: "white",
                                objectFit: "contain",
                                filter: isHovered ? "blur(1px)" : "blur(0)",
                                transition: "filter 0.1s ease-in-out",
                                height: "120px",
                                width: "120px",
                                borderRadius: "50%",
                                boxShadow: "rgba(0, 0, 0, 0.30) 0px 3px 8px",
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>

                      {/* <div style={{ alignItems: "center" }}>
                        <i
                          class="fas fa-user-edit"
                          onClick={() => {
                            setModalEditFoto(true);
                          }}
                          style={{
                            cursor: "pointer",
                            marginBottom: 10,
                            marginTop: 10,
                            fontSize: 20,
                          }}
                        ></i>
                      </div> */}

                      <Card.Title
                        style={{
                          fontSize: 14,
                          marginTop: 10,
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {auth.nopeg}
                      </Card.Title>
                    </a>
                    <p className="card-description" style={{ fontSize: 16 }}>
                      {auth.nama}
                    </p>
                  </div>
                  {/* <Row className="align-items-center">
                    <Col sm="4">
                      <label style={{ fontSize: 14, color: "black" }}>
                        Email :
                      </label>
                    </Col>
                    <Col sm="8">
                      <label style={{ fontSize: 14, color: "black" }}>
                        {getEmail || "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col sm="4">
                      <label style={{ fontSize: 14, color: "black" }}>
                        No HP :
                      </label>
                    </Col>
                    <Col sm="8">
                      <label style={{ fontSize: 14, color: "black" }}>
                        {getNoHp || "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col sm="4">
                      <label style={{ fontSize: 14, color: "black" }}>
                        Alamat :
                      </label>
                    </Col>
                    <Col sm="8">
                      <label style={{ fontSize: 14, color: "black" }}>
                        {getAlamat || "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col sm="4">
                      <label style={{ fontSize: 14, color: "black" }}>
                        Tgl Lahir :
                      </label>
                    </Col>
                    <Col sm="8">
                      <label style={{ fontSize: 14, color: "black" }}>
                        {" "}
                        {moment(getTglLahir).isValid()
                          ? moment(getTglLahir).format("DD MMMM YYYY")
                          : "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col sm="4">
                      <label style={{ fontSize: 14, color: "black" }}>
                        Kode User :
                      </label>
                    </Col>
                    <Col sm="8">
                      <label style={{ fontSize: 14, color: "black" }}>
                        {getKodeUser || "-"}
                      </label>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col sm="4">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Email
                      </label>
                    </Col>
                    <Col sm="0">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          display: "block",
                        }}
                      >
                        {getEmail || "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        No HP
                      </label>
                    </Col>
                    <Col sm="0">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {getNoHp || "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Alamat
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "capitalize",
                          fontSize: 14,
                          color: "black",
                          display: "block",
                        }}
                      >
                        {getAlamat || "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Tgl Lahir
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "capitalize",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {moment(getTglLahir).isValid()
                          ? moment(getTglLahir).format("DD MMMM YYYY")
                          : "-"}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Kode User
                      </label>
                    </Col>
                    <Col sm="o">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </label>
                    </Col>
                    <Col sm="6">
                      <label
                        style={{
                          textTransform: "none",
                          fontSize: 14,
                          color: "black",
                        }}
                      >
                        {getKodeUser || "-"}
                      </label>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default UserProfile;
