import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
import { reduxSoldierMiddleware } from "redux-soldier";
//import axios from "axios";

//Reducer Umum
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import monitoringReducer from "./monitoring";

// reducer BPD Kaltimtara
import userReducerKaltimtara from "./bpdKaltimtara/user";
import dashboardUtamaReducerKaltimtara from "./bpdKaltimtara/dashboardUtama";
import clientReducerKaltimtara from "./bpdKaltimtara/client";
import korwilReducerKaltimtara from "./bpdKaltimtara/korwil";
import areaManagerReducerKaltimtara from "./bpdKaltimtara/areaManager";
import marketingReducerKaltimtara from "./bpdKaltimtara/marketing";
import supervisorReducerKaltimtara from "./bpdKaltimtara/supervisor";
import cabangReducerKaltimtara from "./bpdKaltimtara/cabang";
import pengajuanReducerKaltimtara from "./bpdKaltimtara/Pengajuan";

//Reducer BPD Kalteng
import dashboardUtamaReducerKalteng from "./bpdKalteng/dashboardUtama";
import monitoringReducerKalteng from "./bpdKalteng/monitoring";
import userReducerKalteng from "./bpdKalteng/user";
import clientReducerKalteng from "./bpdKalteng/client";
import korwilReducerKalteng from "./bpdKalteng/korwil";
import areaManagerReducerKalteng from "./bpdKalteng/areaManager";
import marketingReducerKalteng from "./bpdKalteng/marketing";
import supervisorReducerKalteng from "./bpdKalteng/supervisor";
import cabangReducerKalteng from "./bpdKalteng/cabang";
import pengajuanReducerKalteng from "./bpdKalteng/Pengajuan";

//Reducer BPD Sulutgo
import userReducerSulutgo from "./bpdSulutgo/user";
import clientReducerSulutgo from "./bpdSulutgo/client";
import korwilReducerSulutgo from "./bpdSulutgo/korwil";
import areaManagerReducerSulutgo from "./bpdSulutgo/areaManager";
import marketingReducerSulutgo from "./bpdSulutgo/marketing";
import supervisorReducerSulutgo from "./bpdSulutgo/supervisor";
import cabangReducerSulutgo from "./bpdSulutgo/cabang";
import pengajuanReducerSulutgo from "./bpdSulutgo/Pengajuan";
import dashboardUtamaReducerSulutgo from "./bpdSulutgo/dashboardUtama";

//Reducer BPD Kalsel
import monitoringReducerKalsel from "./bpdKalsel/monitoring";
import userReducerKalsel from "./bpdKalsel/user";
import clientReducerKalsel from "./bpdKalsel/client";
import korwilReducerKalsel from "./bpdKalsel/korwil";
import areaManagerReducerKalsel from "./bpdKalsel/areaManager";
import marketingReducerKalsel from "./bpdKalsel/marketing";
import supervisorReducerKalsel from "./bpdKalsel/supervisor";
import cabangReducerKalsel from "./bpdKalsel/cabang";
import pengajuanReducerKalsel from "./bpdKalsel/Pengajuan";
import dashboardUtamaReducerKalsel from "./bpdKalsel/dashboardUtama";

// Function Umum
export * from "./auth/function";
export * from "./monitoring/function";
export * from "./errorHandler";

// function BPD Kaltimtara
export * from "./bpdKaltimtara/user/function";
export * from "./bpdKaltimtara/dashboardUtama/function";
export * from "./bpdKaltimtara/client/function";
export * from "./bpdKaltimtara/korwil/function";
export * from "./bpdKaltimtara/areaManager/function";
export * from "./bpdKaltimtara/marketing/function";
export * from "./bpdKaltimtara/supervisor/function";
export * from "./bpdKaltimtara/cabang/function";

//FUnction BPD Kalteng
export * from "./bpdKalteng/dashboardUtama/function";
export * from "./bpdKalteng/monitoring/function";
export * from "./bpdKalteng/user/function";
export * from "./bpdKalteng/client/function";
export * from "./bpdKalteng/korwil/function";
export * from "./bpdKalteng/areaManager/function";
export * from "./bpdKalteng/marketing/function";
export * from "./bpdKalteng/supervisor/function";
export * from "./bpdKalteng/cabang/function";

//Function BPD Kalteng
export * from "./bpdSulutgo/user/function";
export * from "./bpdSulutgo/client/function";
export * from "./bpdSulutgo/korwil/function";
export * from "./bpdSulutgo/areaManager/function";
export * from "./bpdSulutgo/marketing/function";
export * from "./bpdSulutgo/supervisor/function";
export * from "./bpdSulutgo/cabang/function";
export * from "./bpdSulutgo/dashboardUtama/function";

//Function BPD Kalsel
export * from "./bpdKalsel/monitoring/function";
export * from "./bpdKalsel/user/function";
export * from "./bpdKalsel/client/function";
export * from "./bpdKalsel/korwil/function";
export * from "./bpdKalsel/areaManager/function";
export * from "./bpdKalsel/marketing/function";
export * from "./bpdKalsel/supervisor/function";
export * from "./bpdKalsel/cabang/function";
export * from "./bpdKalsel/dashboardUtama/function";

// server api BPD Kaltimtara
// export const baseUrl = process.env.REACT_APP_URL
// export const baseUrl = "https://newapikaltim.ptbap.net/";
// export const baseUrl = "http://192.168.100.123:9050/";
// export const baseUrl = "http://192.168.100.40:9050/";
// export const baseUrl = "http://localhost:9050/";
// export const baseUrlKaltimtara = "https://newapibpd.waroengapp.my.id/";
export const baseUrlKaltimtara = "https://newapikaltim.ptbap.net/";
export const baseUrlKalteng = "https://newapikalteng.ptbap.net/";
export const baseUrlSulutgo = "https://newapisulutgo.ptbap.net";
export const baseUrlKalsel = "https://apikalsel.ptbap.net/";

export const baseAxiosKaltimtara = axios.create({
  baseURL: baseUrlKaltimtara,
  //timeout: 10000,
});
export const baseAxiosKalteng = axios.create({
  baseURL: baseUrlKalteng,
  //timeout: 10000,
});

export const baseAxiosSulutgo = axios.create({
  baseURL: baseUrlSulutgo,
  //timeout: 10000,
});

export const baseAxioKalsel = axios.create({
  baseURL: baseUrlKalsel,
  //timeout: 10000,
});
const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  monitoringReducer,

  //Router BPD Kaltimtara
  userReducerKaltimtara,
  dashboardUtamaReducerKaltimtara,
  clientReducerKaltimtara,
  korwilReducerKaltimtara,
  areaManagerReducerKaltimtara,
  marketingReducerKaltimtara,
  supervisorReducerKaltimtara,
  cabangReducerKaltimtara,
  pengajuanReducerKaltimtara,

  //Router BPD Kalteng
  dashboardUtamaReducerKalteng,
  clientReducerKalteng,
  userReducerKalteng,
  monitoringReducerKalteng,
  korwilReducerKalteng,
  areaManagerReducerKalteng,
  marketingReducerKalteng,
  supervisorReducerKalteng,
  cabangReducerKalteng,
  pengajuanReducerKalteng,

  // Router BPD Sulutgo
  userReducerSulutgo,
  clientReducerSulutgo,
  korwilReducerSulutgo,
  areaManagerReducerSulutgo,
  marketingReducerSulutgo,
  supervisorReducerSulutgo,
  cabangReducerSulutgo,
  pengajuanReducerSulutgo,
  dashboardUtamaReducerSulutgo,

  //Router BPD Kalsel

  userReducerKalsel,
  clientReducerKalsel,
  monitoringReducerKalsel,
  korwilReducerKalsel,
  areaManagerReducerKalsel,
  marketingReducerKalsel,
  supervisorReducerKalsel,
  cabangReducerKalsel,
  pengajuanReducerKalsel,
  dashboardUtamaReducerKalsel,
});

// export const store = createStore(
//   rootReducer,
//   composeWithDevTools(applyMiddleware(thunk))
// );

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
