const initialState = {
  listClient: [],
};

const clientReducerKalsel = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLCLIENT":
      return {
        ...state,
        listClient: data,
      };
    default:
      return state;
  }
};
export default clientReducerKalsel;
