const initialState = {
  listAreaManager: [],
  statusInputTargetAM: true,
  listTargetAreaManager: [],
};

const areaManagerReducerSulutgo = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLAREAMANAGER":
      return {
        ...state,
        listAreaManager: data,
      };
    case "SET_STATUS_INPUT_TARGET_AREAMANAGER":
      return {
        ...state,
        statusInputTargetAM: data,
      };
    case "SET_ALL_TARGET_AREAMANAGER":
      return {
        ...state,
        listTargetAreaManager: data,
      };
    default:
      return state;
  }
};
export default areaManagerReducerSulutgo;
